import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Air type" source="air_type.name_th_contains" />
    </Filter>
)

const ServiceAirCleanList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Air type" source="air_type.name_th" />
            <TextField label="Btu size" source="btu_size.name" />
            <TextField label="price" source="price" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ServiceAirCleanList
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import People from '@material-ui/icons/People';
import GroupWork from '@material-ui/icons/GroupWork';
import Car from '@material-ui/icons/DriveEta';
import Air from '@material-ui/icons/Cloud';
import Maid from '@material-ui/icons/Home';
import Health from '@material-ui/icons/LocalHospital';
import SOS from '@material-ui/icons/Build';
import Virus from '@material-ui/icons/Opacity';
import Order from '@material-ui/icons/ListAlt';
import Promo from '@material-ui/icons/Money';
import System from '@material-ui/icons/Dvr';
import Food from '@material-ui/icons/Restaurant';
import Security from '@material-ui/icons/Security';
import Shop from '@material-ui/icons/ShoppingCart';
import Software from '@material-ui/icons/DevicesOther';
import Activity from '@material-ui/icons/ConfirmationNumber';

import { MenuItemLink, getResources } from 'react-admin';

import SubMenu from './SubMenu';
import { storage } from '../services';

const Menu = ({ onMenuClick, dense }) => {
    const [state, setState] = useState({
        MenuPackages: false,
        MenuOrders: false,
        MenuPromotions: false,
        MenuManagements: false,
        MenuAdditionals: false,
        MenuUsers: false,
        MenuMaid: false,
        MenuAir: false,
        MeunuHealth: false,
        MenuTran: false,
        MenuSos: false,
        MenuVirus: false,
        MenuFood: false,
        MenuSMS: false,
        MenuShop: false,
        MenuSoftware: false,
        MenuActivity: false,
        MenuCovid: false,
        MenuCovidInstant: false,
    });
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources)
    const permission = storage.load('permission')
    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };
    const renderMenu = (title, icon, resource, menu) => {
        return (
            <React.Fragment>
                {permission === 'operator' ? title === 'Orders' && <SubMenu
                    handleToggle={() => handleToggle(menu)}
                    isOpen={state[menu]}
                    sidebarIsOpen={open}
                    name={title}
                    icon={icon}
                    dense={dense}
                >
                    {resource.map(resource => (
                        MenuItem(resource, onMenuClick, open)
                    ))}
                </SubMenu> :
                    title === 'Service' ?
                        renderSubMenu(title, icon, resource, menu)
                        :
                        <SubMenu
                            handleToggle={() => handleToggle(menu)}
                            isOpen={state[menu]}
                            sidebarIsOpen={open}
                            name={title}
                            icon={icon}
                            dense={dense}
                        >
                            {resource.map(resource => (
                                MenuItem(resource, onMenuClick, open)
                            ))}
                        </SubMenu>}
            </React.Fragment>
        )
    }
    const renderSubMenu = (title, icon, resource, menu) => {
        return (
            <React.Fragment>
                <SubMenu
                    handleToggle={() => handleToggle(menu)}
                    isOpen={state[menu]}
                    sidebarIsOpen={open}
                    name={title}
                    icon={icon}
                    dense={dense}
                >
                    {renderInSubMenu('Maid', <Maid />, resource.filter(r => r.options.sub === 'maid'), 'MenuMaid')}
                    {renderInSubMenu('Air Cleaner', <Air />, resource.filter(r => r.options.sub === 'air-cleaner'), 'MenuAir')}
                    {renderInSubMenu('Health', <Health />, resource.filter(r => r.options.sub === 'health'), 'MenuHealth')}
                    {renderInSubMenu('Transportation', <Car />, resource.filter(r => r.options.sub === 'transportation'), 'MenuTran')}
                    {renderInSubMenu('SOS', <SOS />, resource.filter(r => r.options.sub === 'sos'), 'MenuSos')}
                    {renderInSubMenu('Virus Cleaner', <Virus />, resource.filter(r => r.options.sub === 'virus'), 'MenuVirus')}
                    {renderInSubMenu('Food', <Food />, resource.filter(r => r.options.sub === 'food'), 'MenuFood')}
                    {renderInSubMenu('Security', <Security />, resource.filter(r => r.options.sub === 'security'), 'MenuSMS')}
                    {renderInSubMenu('Shop', <Shop />, resource.filter(r => r.options.sub === 'shop'), 'MenuShop')}
                    {renderInSubMenu('Software', <Software />, resource.filter(r => r.options.sub === 'software'), 'MenuSoftware')}
                    {renderInSubMenu('Voucher', <Activity />, resource.filter(r => r.options.sub === 'activity'), 'MenuActivity')}
                    {renderInSubMenu('Covid', <Health />, resource.filter(r => r.options.sub === 'covid'), 'MenuCovid')}
                    {renderInSubMenu('Covid-Instant', <Health />, resource.filter(r => r.options.sub === 'instant'), 'MenuCovidInstant')}
                    {resource.filter(r => !r.options.sub).map(resource => (
                        MenuItem(resource, onMenuClick, open)
                    ))}
                </SubMenu>
            </React.Fragment>
        )
    }
    const renderInSubMenu = (title, icon, resource, menu) => {
        return (
            <SubMenu
                handleToggle={() => handleToggle(menu)}
                isOpen={state[menu]}
                sidebarIsOpen={open}
                name={title}
                icon={icon}
                dense={dense}
            >
                {resource.map(resource => (
                    MenuItem(resource, onMenuClick, open)
                ))}
            </SubMenu>
        )
    }
    return (
        <React.Fragment>
            {resources.filter(r => r.hasList && !r.options.type).map(resource => (
                MenuItem(resource, onMenuClick, open, dense)
            ))}
            {renderMenu('Order', <Order />, resources.filter(r => r.hasList && r.options.type === 'order'), 'MenuOrders')}
            {renderMenu('Promo', <Promo />, resources.filter(r => r.hasList && r.options.type === 'promotion'), 'MenuPromotions')}
            {renderMenu('Service', <GroupWork />, resources.filter(r => r.hasList && r.options.type === 'service'), 'MenuPackages')}
            {renderMenu('System', <System />, resources.filter(r => r.hasList && r.options.type === 'system'), 'MenuManagements')}
            {/* {renderMenu('Additionals', <People />, resources.filter(r => r.hasList && r.options.type === 'additional'), 'MenuAdditionals')} */}
            {renderMenu('Users', <People />, resources.filter(r => r.hasList && r.options.type === 'user'), 'MenuUsers')}
        </React.Fragment>
    );
};

const MenuItem = (resource, onMenuClick, open, dense) => {
    return (
        <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={resource.options && resource.options.label || resource.name}
            leftIcon={<resource.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
        />)
}

export default Menu;
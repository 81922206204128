import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, PasswordInput } from 'react-admin';
import { required, requiredEmail } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput type="email" source="email" label="Email" fullWidth validate={[requiredEmail()]} />
        <PasswordInput source="password" label="Password" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { SimpleForm, TextInput, BooleanInput, ReferenceField, TextField } from 'react-admin';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <ReferenceField label="Order-Service" source="order_id" reference="orderservices">
            <TextField source="ref" />
        </ReferenceField>
        <TextField source="km" />
        <TextField source="rider" />
        <TextField source="status" />
        <TextField source="ref" />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { List, Datagrid, TextField, Filter, ReferenceField, SelectField, ReferenceInput, SelectInput, DateField } from 'react-admin';
import TYPE from '../orderservices/orderstatustype';
import { DateInput } from 'react-admin-date-inputs';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="order_id.main_service" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <DateInput label="Service date time more than" source="createdAt_gte" options={{ format: 'dd/MM/yyyy' }} />
        <DateInput label="Service date time less than" source="createdAt_lte" options={{ format: 'dd/MM/yyyy' }} />
    </Filter>
)

const StatusTimeList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid>
            <ReferenceField label="Order" source="order_id._id" reference="orderservices">
                <TextField source="ref" />
            </ReferenceField>
            <ReferenceField label="Main Service" source="order_id._id" reference="orderservices">
                <TextField source="main_service.name_th" />
            </ReferenceField>
            <SelectField source="status" choices={TYPE} />
            <DateField label="Created At" source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            />
        </Datagrid>
    </List>
);

export default StatusTimeList
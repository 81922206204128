import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="type" label="Type" fullWidth validate={[required()]} />
        {props.record.is_config_qr && <BooleanInput label="Config QR" source="is_config_qr" />}
        <BooleanInput label="Default" source="default" />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
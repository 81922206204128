import React from 'react';
import {
    List, Datagrid, TextField, Filter, AutocompleteInput, ChipField, ReferenceInput, SelectInput, EditButton, NumberField,
    ReferenceField, BooleanField, BooleanInput
} from 'react-admin';

const ListDataFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Car Brand" source="car_brand._id" reference="carbrands">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Car Model" source="car_model._id" reference="carmodels">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Sub services" source="sub_service" reference="subservices" filterToQuery={searchText => ({ name_th_contains: searchText })}>
            <AutocompleteInput optionText="name_th" />
        </ReferenceInput>
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ListData = props => (
    <List {...props}
        filters={<ListDataFilter />}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="edit">
            <ReferenceField label="Car Brand" source="car_brand._id" reference="carbrands">
                <TextField source="name_th" />
            </ReferenceField>
            <ReferenceField label="Car Model" source="car_model._id" reference="carmodels">
                <TextField source="name_th" />
            </ReferenceField>
            <ReferenceField label="Sub services" source="sub_service._id" reference="subservices">
                <TextField source="name_th" />
            </ReferenceField>
            <ChipField label="Gear type" source="gear_type" />
            <NumberField label="Price" source="price" options={{ style: 'currency', currency: 'THB' }} />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default ListData

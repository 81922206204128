import React from 'react';
import { List, Datagrid, DateField, Filter, ReferenceInput, NumberField, SelectInput, EditButton, ReferenceField, TextField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="main_service" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const ExtraPeriodList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <DateField label="Start time" source="start_time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <DateField label="End time" source="end_time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <NumberField label="Extra" source="extra" options={{ style: 'currency', currency: 'THB' }} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default ExtraPeriodList
import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Main Service"
                        source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                        reference="mainservices"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="hours" label="Hours" fullWidth validate={[required()]} />
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
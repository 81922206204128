import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            source={formData.prov_id && formData.prov_id._id ? "prov_id._id" : "prov_id._id"}
                            reference="providerlights"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <BooleanInput label="Is checkbox" source="is_checkbox" />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Select from 'react-select';
import { createMuiTheme, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { storage } from '../../services';
import API from '../../configs/API';
import { changeCountOrderCancel } from '../../reducers/CountOrderCancelReducer';
import { changeCountOrderComplete } from '../../reducers/CountOrderCompleteReducer';
import { changeCountOrderWait } from '../../reducers/CountOrderWaitingReducer';
import { changeCountOrderCustomerCancel } from '../../reducers/CountOrderCustomerCancelReducer';
import { changeCountOrderRefund } from '../../reducers/CountOrderRefundReducer';

import OrderStatus from '../../services/OrderStatus';
import { RefundStatus } from '../../services/OrderStatus';

const defaultMaterialTheme = createMuiTheme({
    overrides: {
        MuiInputBase: {
            input: {
                height: 25,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'hsl(0,0%,80%)',
                borderRadius: 4,
                backgroundColor: 'hsl(0,0%,100%)',
                padding: '6px 10px 7px 10px'
            },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: '0px'
                }
            }
        },
    }
});

const styles = theme =>
    createStyles({
        root: {
            minWidth: 200,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 16,
        },
        pos: {
            marginBottom: 12,
        },
        body: {
            fontSize: 20,
        },
        footer: {
            fontSize: 14,
            paddingLeft: 10
        }
    });

const serviceList = [
    { label: 'All', value: 'all' },
    { label: 'Air cleaning service', value: '5dda665b201335776bcd112b' },
    { label: 'Maid', value: '5ddab270201335776bcd1161' },
    { label: 'Airport transfer service', value: '5ddab5b1201335776bcd1171' },
    { label: 'Towing car', value: '5df112eb153f322dc7905135' },
    { label: 'Emergency repairman', value: '5df117ce34e6ab000df0b736' },
    { label: 'Home Registered Nurse', value: '5e6a55c6c1f5461a3dc19af0' },
    { label: 'Disinfectant service', value: '5e739f2fcb2862b936a2acc7' },
    { label: 'Registered Nurse', value: '5e78548a4edc59000f6b80fe' },
    { label: 'Occupational therapy service', value: '5e875bd04dfd78dbfbee6e2f' },
    { label: 'Physical therapy service', value: '5e875c574dfd78dbfbee6e32' },
    { label: 'Child, Midwife and Elderly Care', value: '5e8e23ca49acbd000fcb6064' }]

const token = storage.load("token");
const fecthOption = {
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    },
};

class Dashboard extends React.Component {
    state = {
        mainservice: serviceList[0],
        startDate: moment().startOf('year').toISOString(),
        endDate: moment().endOf('year').toISOString(),
        toDayCancel: 0,
        toDayComplete: 0,
        toDayCustomerCancel: 0,
        toDayWait: 0,
        toDayRefund: 0,
    }
    componentDidMount() {
        this.fetchOrder();
        this.fetchOrderToday();
        this.fetchOrderCancel();
    }

    fetchOrderToday = (params = '') => {
        const startDay = moment().startOf('day').toISOString()
        const endDay = moment().endOf('day').toISOString()
        fetch(`${API}/ordercanceleds/count-with-filtering?start_date=${startDay}&end_date=${endDay}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.setState({ toDayCancel: responseJson })
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Completed}&status_start_date=${startDay}&status_end_date=${endDay}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.setState({ toDayComplete: responseJson })
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Confirmed}&status_start_date=${startDay}&status_end_date=${endDay}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.setState({ toDayWait: responseJson })
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Cancelled}&status_start_date=${startDay}&status_end_date=${endDay}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.setState({ toDayCustomerCancel: responseJson })
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Cancelled}&status_refund=${RefundStatus.Completed}&status_start_date=${startDay}&status_end_date=${endDay}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.setState({ toDayRefund: responseJson })
            }
        });
    }
    fetchOrderCancel = (params = '') => {
        fetch(`${API}/ordercanceleds/count-with-filtering?${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeCountOrderCancel(responseJson);
            }
        });
    }
    fetchOrder = (params = '') => {
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Completed}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeCountOrderComplete(responseJson);
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Confirmed}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeCountOrderWait(responseJson);
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Cancelled}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeCountOrderCustomerCancel(responseJson);
            }
        });
        fetch(`${API}/orderservices/count-with-filtering?status=${OrderStatus.Cancelled}&status_refund=${RefundStatus.Completed}&${params}`, fecthOption).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeCountOrderRefund(responseJson);
            }
        });
    }

    onChangeService = (e) => {
        const { startDate, endDate, mainservice } = this.state;
        this.setState({ mainservice: e })
        if (e.value === 'all') {
            this.fetchOrder(`status_start_date=${startDate}&status_end_date=${endDate}`);
            this.fetchOrderToday();
            this.fetchOrderCancel(`start_date=${startDate}&end_date=${endDate}`);
        } else {
            this.fetchOrder(`main_service=${e.value}&status_start_date=${startDate}&status_end_date=${endDate}`)
            this.fetchOrderToday(`main_service=${e.value}`)
            this.fetchOrderCancel(`main_service=${e.value}&start_date=${startDate}&end_date=${endDate}`);
        }
        this.forceUpdate()
    }

    onChangeDate = (date, e) => {
        this.setState({ [date]: e.toISOString() })
        const { startDate, endDate, mainservice } = this.state;
        if (mainservice.value === 'all') {
            this.fetchOrder(`status_start_date=${date === 'startDate' ? e.toISOString() : startDate}&status_end_date=${date === 'endDate' ? e.toISOString() : endDate}`);
            this.fetchOrderCancel(`start_date=${date === 'startDate' ? e.toISOString() : startDate}&end_date=${date === 'endDate' ? e.toISOString() : endDate}`);
        } else {
            this.fetchOrder(`main_service=${mainservice.value}&status_start_date=${date === 'startDate' ? e.toISOString() : startDate}&status_end_date=${date === 'endDate' ? e.toISOString() : endDate}`)
            this.fetchOrderCancel(`main_service=${mainservice.value}&start_date=${date === 'startDate' ? e.toISOString() : startDate}&end_date=${date === 'endDate' ? e.toISOString() : endDate}`);
        }
    }

    render() {
        const { toDayCancel, toDayComplete, toDayCustomerCancel, toDayRefund, toDayWait } = this.state;
        const { classes, orderCancel, orderComplete, orderWait, orderCustomerCancel, orderRefund } = this.props
        const card = [
            { title: 'อัตรางานที่อยู่ระหว่างรอรับบริการ', body: orderWait, footer: `วันนี้ ${toDayWait}` },
            { title: 'อัตรางานที่สมบูรณ์แล้ว', body: orderComplete, footer: `วันนี้ ${toDayComplete}` },
            { title: 'อัตราการแคนเซิลงานจากลูกค้า', body: orderCustomerCancel, footer: `วันนี้ ${toDayCustomerCancel}` },
            { title: 'อัตราการแคนเซิลงานของProvider', body: orderCancel, footer: `วันนี้ ${toDayCancel}` },
            { title: 'อัตราการรีฟันด์', body: orderRefund, footer: `วันนี้ ${toDayRefund}` }
        ]
        return (
            <React.Fragment>
                <div style={{ paddingTop: 30, display: 'flex' }}>
                    <div style={{ width: 300 }}>
                        <Typography className={classes.title}>
                            {'Main service'}
                        </Typography>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            onChange={(e) => this.onChangeService(e)}
                            value={this.state.mainservice}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={serviceList}
                        />
                    </div>
                    <div style={{ paddingTop: 7, paddingLeft: 20 }}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label="Start Date"
                                    format="yyyy/MM/dd"
                                    value={this.state.startDate}
                                    onChange={(e) => this.onChangeDate('startDate', e)}
                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </div>
                    <div style={{ paddingTop: 7, paddingLeft: 20 }}>
                        <ThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    autoOk
                                    label="End Date"
                                    format="yyyy/MM/dd"
                                    value={this.state.endDate}
                                    onChange={(e) => this.onChangeDate('endDate', e)}
                                />
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </div>
                </div>
                <div style={{ paddingTop: 30 }}>
                    <Grid container spacing={3}>
                        {card.map(each => {
                            return (<Grid item xs>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            {each.title}</Typography>
                                        <Typography className={classes.body}>
                                            {each.body}
                                        </Typography>
                                    </CardContent>
                                    <Divider variant="middle" />
                                    <CardActions>
                                        <Typography className={classes.footer} gutterBottom>
                                            {each.footer}
                                        </Typography>
                                    </CardActions>
                                </Card>
                            </Grid>)
                        })}
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    width: PropTypes.string,
};

const mapStateToProps = state => ({
    orderWait: state.orderWait,
    orderComplete: state.orderComplete,
    orderCancel: state.orderCancel,
    orderCustomerCancel: state.orderCustomerCancel,
    orderRefund: state.orderRefund,
});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeCountOrderCancel,
            changeCountOrderComplete,
            changeCountOrderWait,
            changeCountOrderCustomerCancel,
            changeCountOrderRefund,
        },
    ),
    withStyles(styles),
    withWidth()
);

export default enhance(Dashboard);

import React from 'react';
import { List, Datagrid, TextField, EditButton, TextInput, Filter, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Logo name" source="logo_name_contains" />
    </Filter>
)

const MgmtCustomProviderInfoList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Logo name" source="logo_name" />
            <TextField label="Tel" source="tel" />
            <TextField source="email" label="Email" />
            <TextField source="office_location" label="Office location" />
            <EditButton />
        </Datagrid>
    </List>
);

export default MgmtCustomProviderInfoList
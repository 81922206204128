import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, ReferenceInput, SelectInput, NumberField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
    </Filter>
)

const MgmtProviderComList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providerlights">
                <TextField source="company_name" />
            </ReferenceField>
            <NumberField label="Percent" source="percent" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default MgmtProviderComList
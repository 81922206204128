const TYPE = [
    { id: 'sub_service', name: 'Sub Service' },
    { id: 'topup_package', name: 'Topup Package' },
    { id: 'promo_service', name: 'Promotion Service' },
    { id: 'promo_topup', name: 'Promotion Topup' },
    { id: 'url', name: 'URL' },
]

const TYPE_OBJ = {
    SUB_SERVICE: 'sub_service',
    TOPUP_PACKAGE: 'topup_package',
    PROMO_SERVICE: 'promo_service',
    PROMO_TOPUP: 'promo_topup',
    URL: 'url'
}
export { TYPE_OBJ }
export default TYPE
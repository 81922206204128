import React from 'react';
import { Edit } from 'react-admin';
import Form from './Form'
import { CustomToolbar } from '../cornrefunds/Edit';

const FormEdit = (props) => {
    return (
        <Edit {...props}>
            <Form toolbar={<CustomToolbar />} />
        </Edit>
    )
};

export default FormEdit 

import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, SelectField, BooleanField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)
const choices = [
    { id: 'value', name: 'บาท' },
    { id: 'percent', name: '% (เปอร์เซ็นต์)' },
];
const PromoShippingList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <SelectField choices={choices} source="promo_type" />
            <TextField label="Discount" source="discount" />
            <TextField label="Min purchase" source="min_purchase" />
            <TextField label="Qty" source="qty" />
            <BooleanField source="visible" />
            <EditButton />
        </Datagrid>
    </List>
);

export default PromoShippingList
const CHANGE_TOWING_CAR_NOTIFICATION = 'CHANGE_TOWING_CAR_NOTIFICATION';

export const changeTowingCarNotification = notification => ({
    type: CHANGE_TOWING_CAR_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_TOWING_CAR_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

import React from 'react';
import { fetchUtils, Admin, Resource, ListGuesser } from 'react-admin';
import { simpleRestProvider, authProvider, requesthandler, storage } from './services'
import {
    subservices, serviceareas, providers, servicegroups, mainservices, additionalservices, airtypes, banks,
    btusizes, carbrands, carmodels, cars, conditionofservices, employees, internalapps, mgmtpredays, mgmtrefunds,
    orderservices, orderrefunds, perhours, places, promocodes, promotopups, residencetypes, roomsizes, serviceaircleans,
    topuppackages, servicecarwdairports, banners, users, costsubs, servicemaids, thirdparty, ordertopups, specialholidays,
    promoservices, cancelreasons, garages, cartypes, enginesizes, mgmtcoms, extraprovinces, cornrefunds, paymentterms,
    perkms, repairtypes, servicesostowingcars, servicesosrepairs, garageservices, mgmtcomingsoons, extraperiods,
    towingcarbrands, towingcarmodels, statustimes, serviceregisnurzs, servicenurzs, visitnurztypes, vouchers,
    serviceviruscleaners, antiseptictypes, areasizes, vendors, serviceoccupationaltherapies, servicephysicaltherapies,
    symptomtypes, servicehealthcares, serviceliquidfoods, elderlytypes, servicepatientfoods, meals,
    servicefood, accounting, consumertypes, foodtypes, gpprices, mgmtprovidercoms, subservicepromos, orderriders,
    tools, coursetypes, servicesmsvips, servicesmsevents, servicesmscourses, orderpromos, riders, promoshippings,
    ordercashbacks, stockupcashbacks, categorytypes, shippingofconditions, shippingtags, shippingtypes, optiontypes,
    foodtags, consumertags, categorytags, paymentgatewayconfigmpays, providerpaymentgateways, banktransferconfigs,
    spectypes, spectags, vouchertypes, vouchertags, mgmtservices, mgmtprovidermonthlies, mgmtprovidermonthlybillings,
    mgmtproviderreportmpays, servicedetectdiseases, servicecovidinstants, evtimes, mgmtsenderemails, mgmtcustomproviderinfos,
    acupuncturemassagegroups, acupuncturemassagesubgroups
} from './resource';
import { Layout } from './layout';
import API from './configs/API'
import AirCleaningNotiReducer from './reducers/AirCleaningNotiReducer';
import MaidNotiReducer from './reducers/MaidNotiReducer';
import AirportNotiReducer from './reducers/AirportNotiReducer';
import TowingCarNotiReducer from './reducers/TowingCarNotiReducer';
import RepairNotiReducer from './reducers/RepairNotiReducer';
import HomeRegisNurseNotiReducer from './reducers/HomeRegisNurseNotiReducer';
import RegisNurseNotiReducer from './reducers/RegisNurseNotiReducer';
import VirusNotiReducer from './reducers/VirusNotiReducer';
import OccupationalNotiReducer from './reducers/OccupationalNotiReducer';
import PhysicalNotiReducer from './reducers/PhysicalNotiReducer';
import HealthCareNotiReducer from './reducers/HealthCareNotiReducer';
import CountOrderCancelReducer from './reducers/CountOrderCancelReducer';
import CountOrderWaitingReducer from './reducers/CountOrderWaitingReducer';
import CountOrderCompleteReducer from './reducers/CountOrderCompleteReducer';
import CountOrderCustomerCancelReducer from './reducers/CountOrderCustomerCancelReducer';
import CountOrderRefundReducer from './reducers/CountOrderRefundReducer';
import { Dashboard, Icon } from './resource/dashboard/index'
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = storage.load("token");
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(API, httpClient);
const dataRequest = requesthandler(dataProvider);

const App = () => {
    return (
        <Admin
            layout={Layout}
            customReducers={{
                AirCleningNoti: AirCleaningNotiReducer,
                MaidNoti: MaidNotiReducer,
                AirportNoti: AirportNotiReducer,
                TowingNoti: TowingCarNotiReducer,
                RepairNoti: RepairNotiReducer,
                HomeRegisNoti: HomeRegisNurseNotiReducer,
                RegisNoti: RegisNurseNotiReducer,
                VirusNoti: VirusNotiReducer,
                OccupationalNoti: OccupationalNotiReducer,
                PhysicalNoti: PhysicalNotiReducer,
                HealthCareNoti: HealthCareNotiReducer,
                orderCancel: CountOrderCancelReducer,
                orderWait: CountOrderWaitingReducer,
                orderComplete: CountOrderCompleteReducer,
                orderCustomerCancel: CountOrderCustomerCancelReducer,
                orderRefund: CountOrderRefundReducer,
            }}
            authProvider={authProvider}
            dataProvider={dataRequest}>
            <Resource name="dashboard" list={Dashboard} icon={Icon} options={{ label: 'Dashboard' }} />
            <Resource name="orderservices" {...orderservices} options={{ label: 'Order-service', type: 'order' }} />
            <Resource name="ordercashbacks" {...ordercashbacks} options={{ label: 'Order-Cashback', type: 'order' }} />
            <Resource name="orderpromos" {...orderpromos} options={{ label: 'Order-Promo', type: 'order' }} />
            <Resource name="orderriders" {...orderriders} options={{ label: 'Order-Rider', type: 'order' }} />
            <Resource name="ordertopups" {...ordertopups} options={{ label: 'Order-topup', type: 'order' }} />
            <Resource name="statustimes" {...statustimes} options={{ label: 'Change Time', type: 'order' }} />

            <Resource name="mainservices" {...mainservices} options={{ label: 'Service', type: 'system' }} />
            <Resource name="subservices" {...subservices} options={{ label: 'Service-Criteria', type: 'system' }} />
            {/* <Resource name="subservicepromos" {...subservicepromos} options={{ label: 'Service-Criteria Promo', type: 'system' }} /> */}
            <Resource name="conditionofservices" {...conditionofservices} options={{ label: 'Terms of service', type: 'system' }} />
            <Resource name="paymentterms" {...paymentterms} options={{ label: 'Payment terms', type: 'system' }} />
            <Resource name="specialholidays" {...specialholidays} options={{ label: 'Special holidays', type: 'system' }} />
            <Resource name="servicegroups" {...servicegroups} options={{ label: 'Group-service', type: 'system' }} />
            <Resource name="mgmtcoms" {...mgmtcoms} options={{ label: 'Commission', type: 'system' }} />
            <Resource name="banks" {...banks} options={{ label: 'Banks', type: 'system' }} />
            <Resource name="banners" {...banners} options={{ label: 'Banners', type: 'system' }} />
            <Resource name="orderservices-accounting" {...accounting} options={{ label: 'Accounting', type: 'system' }} />

            <Resource name="servicemaids" {...servicemaids} options={{ label: 'Maid-Set Price', type: 'service', sub: 'maid' }} />
            <Resource name="roomsizes" {...roomsizes} options={{ label: 'Room sizes', type: 'service', sub: 'maid' }} />
            <Resource name="acupuncturemassagegroups" {...acupuncturemassagegroups} options={{ label: 'Massage Group', type: 'service', sub: 'maid' }} />
            <Resource name="acupuncturemassagesubgroups" {...acupuncturemassagesubgroups} options={{ label: 'Massage Sub-Group', type: 'service', sub: 'maid' }} />

            <Resource name="serviceaircleans" {...serviceaircleans} options={{ label: 'Air-Set Price', type: 'service', sub: 'air-cleaner' }} />
            <Resource name="airtypes" {...airtypes} options={{ label: 'Air types', type: 'service', sub: 'air-cleaner' }} />
            <Resource name="btusizes" {...btusizes} options={{ label: 'Air-Set BTU Size', type: 'service', sub: 'air-cleaner' }} />

            <Resource name="servicecarwdairports" {...servicecarwdairports} options={{ label: 'Car WD Airport', type: 'service', sub: 'transportation' }} />
            <Resource name="places" {...places} options={{ label: 'Set Location', type: 'service', sub: 'transportation' }} />

            <Resource name="servicesosrepairs" {...servicesosrepairs} options={{ label: 'SOS-Repair', type: 'service', sub: 'sos' }} />
            <Resource name="servicesostowingcars" {...servicesostowingcars} options={{ label: 'SOS-Towing', type: 'service', sub: 'sos' }} />
            <Resource name="repairtypes" {...repairtypes} options={{ label: 'Repair types', type: 'service', sub: 'sos' }} />
            <Resource name="towingcarbrands" {...towingcarbrands} options={{ label: 'Repair - car brand', type: 'service', sub: 'sos' }} />
            <Resource name="towingcarmodels" {...towingcarmodels} options={{ label: 'Repair - car type', type: 'service', sub: 'sos' }} />
            <Resource name="garages" {...garages} options={{ label: 'Garages', type: 'service', sub: 'sos' }} />
            <Resource name="garageservices" {...garageservices} options={{ label: `Garage's services`, type: 'service', sub: 'sos' }} />

            <Resource name="serviceregisnurzs" {...serviceregisnurzs} options={{ label: 'Nurse Visit-Set Price', type: 'service', sub: 'health' }} />
            <Resource name="servicenurzs" {...servicenurzs} options={{ label: 'Home Register Nurse', type: 'service', sub: 'health' }} />
            <Resource name="visitnurztypes" {...visitnurztypes} options={{ label: 'Visit nurse types', type: 'service', sub: 'health' }} />
            <Resource name="serviceoccupationaltherapies" {...serviceoccupationaltherapies} options={{ label: 'Occupational Therapy ', type: 'service', sub: 'health' }} />
            <Resource name="servicephysicaltherapies" {...servicephysicaltherapies} options={{ label: 'Physical Therapy', type: 'service', sub: 'health' }} />
            <Resource name="symptomtypes" {...symptomtypes} options={{ label: 'Symptom types', type: 'service', sub: 'health' }} />
            <Resource name="servicehealthcares" {...servicehealthcares} options={{ label: 'Health Care', type: 'service', sub: 'health' }} />
            <Resource name="elderlytypes" {...elderlytypes} options={{ label: 'Elderly types', type: 'service', sub: 'health' }} />

            <Resource name="serviceviruscleaners" {...serviceviruscleaners} options={{ label: 'Virus Cleaner', type: 'service', sub: 'virus' }} />
            <Resource name="antiseptictypes" {...antiseptictypes} options={{ label: 'Anti septic types', type: 'service', sub: 'virus' }} />
            <Resource name="areasizes" {...areasizes} options={{ label: 'Sizes', type: 'service', sub: 'virus' }} />

            {/* <Resource name="servicefoods" {...servicefood} options={{ label: 'Food', type: 'service', sub: 'food' }} /> */}
            <Resource name="foodtypes" {...foodtypes} options={{ label: 'Food Type', type: 'service', sub: 'food' }} />
            <Resource name="foodtags" {...foodtags} options={{ label: 'Food Tag', type: 'service', sub: 'food' }} />
            <Resource name="consumertypes" {...consumertypes} options={{ label: 'Consumer Type', type: 'service', sub: 'food' }} />
            <Resource name="consumertags" {...consumertags} options={{ label: 'Consumer Tag', type: 'service', sub: 'food' }} />

            <Resource name="categorytypes" {...categorytypes} options={{ label: 'Category Type', type: 'service', sub: 'shop' }} />
            <Resource name="categorytags" {...categorytags} options={{ label: 'Category Tag', type: 'service', sub: 'shop' }} />

            <Resource name="spectypes" {...spectypes} options={{ label: 'Spec Type', type: 'service', sub: 'software' }} />
            <Resource name="spectags" {...spectags} options={{ label: 'Spec Tag', type: 'service', sub: 'software' }} />

            <Resource name="vouchertypes" {...vouchertypes} options={{ label: 'Voucher Type', type: 'service', sub: 'activity' }} />
            <Resource name="vouchertags" {...vouchertags} options={{ label: 'Voucher Tag', type: 'service', sub: 'activity' }} />

            <Resource name="servicedetectdiseases" {...servicedetectdiseases} options={{ label: 'Covid-Set Price', type: 'service', sub: 'covid' }} />
            <Resource name="servicecovidinstants" {...servicecovidinstants} options={{ label: 'Covid-Instant-Set Price', type: 'service', sub: 'instant' }} />

            {/* <Resource name="servicefoodelderlies" {...servicefoodelderlies} options={{ label: 'Elderly Food', type: 'service', sub: 'food' }} />
            <Resource name="servicefoodgenerals" {...servicefoodgenerals} options={{ label: 'Generals Food', type: 'service', sub: 'food' }} />
            <Resource name="servicefoodvegetables" {...servicefoodvegetables} options={{ label: 'Vegetables food', type: 'service', sub: 'food' }} /> */}

            {/* <Resource name="serviceliquidfoods" {...serviceliquidfoods} options={{ label: 'Service liquid food', type: 'service' }} /> */}
            {/* <Resource name="servicepatientfoods" {...servicepatientfoods} options={{ label: 'Service patient food', type: 'package' }} /> */}
            {/* <Resource name="distributionservices" {...specialholidays} options={{ label: 'Distribution service', type: 'management' }} /> */}
            <Resource name="servicesmsvips" {...servicesmsvips} options={{ label: 'VIP Protection', type: 'service', sub: 'security' }} />
            <Resource name="servicesmsevents" {...servicesmsevents} options={{ label: 'Event / Workplace Security', type: 'service', sub: 'security' }} />
            <Resource name="servicesmscourses" {...servicesmscourses} options={{ label: 'Course Training', type: 'service', sub: 'security' }} />
            <Resource name="coursetypes" {...coursetypes} options={{ label: 'Course Type', type: 'service', sub: 'security' }} />


            <Resource name="additionalservices" {...additionalservices} options={{ label: 'Additional services', type: 'service' }} />
            <Resource name="cancelreasons" {...cancelreasons} options={{ label: 'Cancel reasons', type: 'service' }} />
            <Resource name="costsubs" {...costsubs} options={{ label: 'Cost subs', type: 'service' }} />
            <Resource name="mgmtservices" {...mgmtservices} options={{ label: 'Mgmt service', type: 'service' }} />
            <Resource name="cars" {...cars} options={{ label: 'Cars', type: 'service' }} />
            <Resource name="carbrands" {...carbrands} options={{ label: 'Car brands', type: 'service' }} />
            <Resource name="carmodels" {...carmodels} options={{ label: 'Car models', type: 'service' }} />
            <Resource name="cartypes" {...cartypes} options={{ label: 'Car types', type: 'service' }} />
            <Resource name="enginesizes" {...enginesizes} options={{ label: 'Engine sizes', type: 'service' }} />
            <Resource name="extraperiods" {...extraperiods} options={{ label: 'Extra time', type: 'service' }} />
            <Resource name="extraprovinces" {...extraprovinces} options={{ label: 'Extra area', type: 'service' }} />
            <Resource name="perhours" {...perhours} options={{ label: 'Extra hour', type: 'service' }} />
            <Resource name="perkms" {...perkms} options={{ label: 'Extra km', type: 'service' }} />
            <Resource name="gpprices" {...gpprices} options={{ label: 'GP Price', type: 'service' }} />
            <Resource name="mgmtpredays" {...mgmtpredays} options={{ label: 'Mgmt predays', type: 'service' }} />
            <Resource name="mgmtcomingsoons" {...mgmtcomingsoons} options={{ label: 'Mgmt comingsoon', type: 'service' }} />
            <Resource name="mgmtprovidercoms" {...mgmtprovidercoms} options={{ label: 'Mgmt Commission Fee', type: 'service' }} />
            <Resource name="residencetypes" {...residencetypes} options={{ label: 'Residence types', type: 'service' }} />
            <Resource name="tools" {...tools} options={{ label: 'Tools', type: 'service' }} />
            <Resource name="riders" {...riders} options={{ label: 'Riders', type: 'service' }} />
            <Resource name="optiontypes" {...optiontypes} options={{ label: 'Option types', type: 'service' }} />

            <Resource name="shippingofconditions" {...shippingofconditions} options={{ label: 'Shipping of conditions', type: 'system' }} />
            <Resource name="shippingtags" {...shippingtags} options={{ label: 'Shipping tags', type: 'system' }} />
            <Resource name="shippingtypes" {...shippingtypes} options={{ label: 'Shipping type', type: 'system' }} />

            <Resource name="evtimes" {...evtimes} options={{ label: 'EV Times', type: 'system' }} />

            <Resource name="paymentgatewayconfigmpays" {...paymentgatewayconfigmpays} options={{ label: 'Config mPAY', type: 'system' }} />
            <Resource name="providerpaymentgateways" {...providerpaymentgateways} options={{ label: 'Payment gateways', type: 'system' }} />
            <Resource name="banktransferconfigs" {...banktransferconfigs} options={{ label: 'Bank transfer configs', type: 'system' }} />
            <Resource name="mgmtprovidermonthlies" {...mgmtprovidermonthlies} options={{ label: 'provider monthly', type: 'system' }} />
            <Resource name="mgmtprovidermonthlybillings" {...mgmtprovidermonthlybillings} options={{ label: 'provider monthly billing', type: 'system' }} />
            <Resource name="mgmtproviderreportmpays" {...mgmtproviderreportmpays} options={{ label: 'provider report mPAY', type: 'system' }} />
            <Resource name="mgmtsenderemails" {...mgmtsenderemails} options={{ label: 'Sender Email', type: 'system' }} />
            <Resource name="mgmtcustomproviderinfos" {...mgmtcustomproviderinfos} options={{ label: 'Custom Provider Info', type: 'system' }} />

            {/* <Resource name="mgmtrefunds" {...mgmtrefunds} options={{ label: 'Mgmt refunds', type: 'management' }} /> */}
            {/* <Resource name="meals" {...meals} options={{ label: 'Meal', type: 'additional' }} /> */}
            {/* <Resource name="cornrefunds" {...cornrefunds} options={{ label: 'Coin refunds', type: 'management' }} /> */}
            {/* <Resource name="distributionpoints" {...specialholidays} options={{ label: 'Distribution point', type: 'management' }} /> */}

            <Resource name="promocodes" {...promocodes} options={{ label: 'Promo-code', type: 'promotion' }} />
            <Resource name="vouchers" {...vouchers} options={{ label: 'Voucher', type: 'promotion' }} />
            <Resource name="promoshippings" {...promoshippings} options={{ label: 'Promo shippings', type: 'promotion' }} />
            {/* <Resource name="promotopups" {...promotopups} options={{ label: 'Promo Topups', type: 'promotion' }} /> */}
            {/* <Resource name="promoservices" {...promoservices} options={{ label: 'Promo Services', type: 'promotion' }} /> */}
            {/* <Resource name="topuppackages" {...topuppackages} options={{ label: 'Topup packages', type: 'package' }} /> */}

            <Resource name="users" {...users} options={{ label: 'Users', type: 'user' }} />
            <Resource name="employees" {...employees} options={{ label: 'Employees', type: 'user' }} />
            <Resource name="thirdparties" {...thirdparty} options={{ label: 'Third Party', type: 'user' }} />
            <Resource name="internalapps" {...internalapps} options={{ label: 'Internal apps', type: 'user' }} />
            <Resource name="providerlights" {...providers} options={{ label: 'Providers', type: 'user' }} />
            {/* <Resource name="vendors" {...vendors} options={{ label: 'Vendors', type: 'user' }} /> */}
            <Resource name="orderrefunds" />
            <Resource name="orderdetails" />
            <Resource name="orderresponsibles" />
            <Resource name="serviceareas" />
            <Resource name="ordercars" />
            <Resource name="clients" />
            <Resource name="responsibles" />
            <Resource name="days" />
            <Resource name="times" />
            <Resource name="orderadditionals" />
            <Resource name="locations" />
            <Resource name="stockupcashbacks" />
            <Resource name="subservicepromos" />
            {/* <Resource name="providerlights" /> */}
        </Admin>
    )
};

export default App;
import React from 'react';
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'
import { TimeInput } from 'react-admin-date-inputs';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Main service"
                        source="main_service.id"
                        reference="mainservices"
                        fullWidth
                        allowEmpty>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <TimeInput source="start_time" validate={[required()]} options={{ format: 'HH:mm:ss' }} />
        <TimeInput source="end_time" validate={[required()]} options={{ format: 'HH:mm:ss' }} />
        <NumberInput source="extra" label="Extra" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput, ImageInput, ImageField, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Acupuncture massage groups"
                            source={formData.acupuncture_massage_group && formData.acupuncture_massage_group._id ? "acupuncture_massage_group._id" : "acupuncture_massage_group._id"}
                            reference="acupuncturemassagegroups"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
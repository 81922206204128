import React from 'react';
import {
    TabbedForm, FormTab, TextInput, BooleanInput, SelectInput, FormDataConsumer, PasswordInput, ImageInput, ImageField, Datagrid, TextField,
    ReferenceManyField, SelectField, ReferenceField, DateField, NumberField, Pagination, ArrayField,
    FunctionField, CheckboxGroupInput, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { required } from '../../services/validate'
import registype, { TYPE_OBJ, CREATE_TYPE } from './registype'
import gendertype from './gendertype'
import localetype from './localetype'
import orderstatustype from '../orderservices/orderstatustype'
import { findAddress } from '../../services/address/finder';

const typeofrole = [{ name: 'Super Admin', id: 1 }, { name: 'Admin', id: 2 }, { name: 'Operator', id: 3 }];

const Form = ({ ...props }) => {
    return (
        <TabbedForm {...props}>
            <FormTab label="Account">
                <TextInput source="username" fullWidth validate={[required()]} disabled={!!props.record._id} />
                <TextInput source="email" fullWidth validate={[required()]} disabled={!!props.record._id} />
                <PasswordInput source="password" fullWidth validate={props.record._id ? null : [required()]} />
                <SelectInput source="regis_type" fullWidth choices={props.record._id ? registype : CREATE_TYPE} validate={[required()]} disabled={!!props.record._id} />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'provider_admin') &&
                        <ReferenceInput label="Provider" source="prov_id" reference="providerlights" fullWidth validate={[required()]}>
                            <AutocompleteInput optionText="company_name" />
                        </ReferenceInput>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'provider_admin') &&
                        <SelectInput source="type_of_role" choices={typeofrole} fullWidth validate={[required()]} />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'provider_admin') &&
                        <CheckboxGroupInput source="permissions" choices={[
                            { id: 'order-responsible', name: 'Order Responsible' },
                            { id: 'order-service', name: 'Order Service' },
                            { id: 'calendar', name: 'Calendar' },
                            { id: 'employee', name: 'Employee' },
                            { id: 'manage-service', name: 'Manage Service' },
                        ]} fullWidth />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'admin' || formData.regis_type === 'provider_admin') &&
                        (!!props.isCreate ? <TextInput label="Firstname" source="firstname" validate={[required()]} fullWidth />
                            : <TextInput label="Firstname" source="admin_id.firstname" validate={[required()]} fullWidth disabled={!!props.record._id} />)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'admin' || formData.regis_type === 'provider_admin') &&
                        (!!props.isCreate ? <TextInput label="Lastname" source="lastname" validate={[required()]} fullWidth /> :
                            <TextInput label="Lastname" source="admin_id.lastname" validate={[required()]} fullWidth disabled={!!props.record._id} />)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'internalapp' || formData.regis_type === 'thirdparty') && <TextInput label="Name" source="name" validate={[required()]} fullWidth />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        (formData.regis_type === 'provider') && <TextInput label="Company name" source="company_name" validate={[required()]} fullWidth />
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        <React.Fragment>
                            {(formData.regis_type === 'internalapp' || formData.regis_type === 'thirdparty') &&
                                <TextInput label="Address" source="provider_id.address" validate={[required()]} fullWidth />}
                            {(formData.regis_type === 'provider') && <TextInput label="Address" source="address" validate={[required()]} fullWidth />}
                        </React.Fragment>
                    }
                </FormDataConsumer>
                {!!props.isCreate && <TextInput label="Tel" source="tel" validate={[required()]} fullWidth />}
                {(!props.isCreate && props.record.regis_type === 'admin') && <TextInput label="Tel" source="admin_id.tel" validate={[required()]} fullWidth disabled={!!props.record._id} />}
                {(!props.isCreate && props.record.regis_type === "client") && <TextInput label="Tel" source="client_id.tel" validate={[required()]} fullWidth disabled={!!props.record._id} />}
                {(!props.isCreate && props.record.regis_type === "provider") && <TextInput label="Tel" source="provider_id.tel" validate={[required()]} fullWidth disabled={!!props.record._id} />}
                {/* <BooleanInput source="confirmed" initialValue={false} />
                <BooleanInput source="blocked" initialValue={false} /> */}
            </FormTab>
            {
                (props.record._id && props.record.regis_type === TYPE_OBJ.CLIENT) &&
                <FormTab label="Information">
                    <TextField label="Citizen ID" source="client_id.citizen_id" />
                    <TextField label="First name" source="client_id.firstname" />
                    <TextField label="Last name" source="client_id.lastname" />
                    <TextField label="Tel" source="client_id.tel" />
                    <SelectField label="Gender" source="client_id.gender" choices={gendertype} />
                    <SelectField label="Locale" source="client_id.locale" choices={localetype} />
                    {
                        (props.record.client_id && props.record.client_id.file_avatar) &&
                        <ImageField label="Avatar" source="client_id.file_avatar.fullUrl" title="client_id.file_avatar.name" />
                    }
                    {/* <TextInput source="client_id.citizen_id" fullWidth />
                    <TextInput source="client_id.firstname" fullWidth validate={[required()]}/>
                    <TextInput source="client_id.lastname" fullWidth validate={[required()]}/>
                    <TextInput source="client_id.tel" fullWidth validate={[required()]}/>
                    <SelectInput source="client_id.gender" choices={gendertype} fullWidth/>
                    <SelectInput source="client_id.locale" choices={localetype} fullWidth/>
                    <ImageInput source="client_id.file_avatar" label="Image" accept="image/*">
                        <ImageField source="fullUrl" title="name" />
                    </ImageInput> */}
                    {
                        props.record.client_id &&
                        <ReferenceManyField
                            label="Order Services"
                            pagination={<Pagination />}
                            reference="orderservices"
                            target=''
                            sort={{ field: 'date_time', order: 'ASC' }}
                            filter={{
                                'client._id': props.record.client_id._id,
                            }}
                            fullWidth
                        >
                            <Datagrid>
                                <ReferenceField label="Ref" source="_id" reference="orderservices">
                                    <TextField source="ref" />
                                </ReferenceField>
                                <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                                    <TextField source="name_th" />
                                </ReferenceField>
                                <SelectField source="status" choices={orderstatustype} />
                                <DateField source="date_time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                                <NumberField source="total" />
                            </Datagrid>
                        </ReferenceManyField>
                    }
                </FormTab>
            }
            {props.record.client_id && <FormTab label="Location">
                <ReferenceManyField
                    label="Location"
                    reference="locations"
                    source="client_id._id"
                    filter={{
                        'client._id': props.record.client_id._id,
                    }}
                    fullWidth>
                    <Datagrid>
                        <TextField source="name" label="Address" />
                        <TextField source="province" />
                        <TextField source="district" />
                        <FunctionField label="Lat/Lng" render={source => <a target="_blank" href={`https://www.google.co.th/maps?q=${source.lat},${source.long}`}>{`${source.lat} ${source.long}`}</a>} />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>}
            {(props.record._id && props.record.regis_type === TYPE_OBJ.ADMIN) && <FormTab label="Permission">
                <SelectInput label="Type of role" source="type_of_role" choices={typeofrole}
                />
                {/* <CheckboxGroupInput source="permissions" /> */}
            </FormTab>}
        </TabbedForm>
    )
};

export default Form;
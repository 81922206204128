import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            source={formData.prov_id && formData.prov_id._id ? "prov_id._id" : "prov_id._id"}
                            reference="providerlights"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Main Service"
                            source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                            reference="mainservices"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
import React, { cloneElement } from 'react';
import {
    List, Datagrid, TextField, Filter, TextInput, EditButton, DateField, ReferenceInput,
    SelectInput, TopToolbar, ExportButton, CreateButton, sanitizeListRestProps
} from 'react-admin';
import { ImportActions } from '../../layout/ImportActions';
import promocode from './promocode.xlsx';
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { invertObj } from 'ramda';
import moment from 'moment';
import OrderStatus from '../../services/OrderStatus';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/icons/CloudDownload";

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Key" source="key_contains" />
        <TextInput label="Name" source="name_th_contains" />
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
    </Filter>
)

const exporter = (records, fetchRelatedRecords) => {
    // console.log(records)
    // records = records.filter((record, index) => record.status >= 4);
    Promise.all(records.map(async (record) => {
        let newRecord = {};
        return {
            ...newRecord,
            id: record._id,
            key: record.key,
            name_th: record.name_th,
            discount: record.discount,
            promo_code_type: record.promo_code_type,
            qty: record.qty,
            min_purchase: record.min_purchase,
            main_service: record.main_service ? record.main_service.name_th : '',
            start_date: moment(record.start_date).format("YYYY-MM-DD HH:mm:ss"),
            end_date: moment(record.end_date).format("YYYY-MM-DD HH:mm:ss"),
        };
    })).then(data => {
        const csv = convertToCSV({
            data,
            fields: ["id", "key", "name_th", "discount", "promo_code_type", "qty", "min_purchase", "main_service", "start_date", "end_date"]
        });
        csvDownload(csv, "promocode");
    });
}

const csvDownload = (csv, filename) => {
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    const blob = new Blob(["\uFEFF" + csv], { type: "text/csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    } else {
        fakeLink.setAttribute("href", URL.createObjectURL(blob));
        fakeLink.setAttribute("download", `${filename}.csv`);
        fakeLink.click();
    }
};

const PromoCodeList = props => (
    <List
        {...props}
        filters={<ListFilter />}
        bulkActionButtons={false}
        actions={<ImportActions title={'Import Promo-code by CSV'} resource="promocodes" example={promocode} isExport />}
        exporter={exporter}
    >
        <Datagrid rowClick="edit">
            <TextField label="Key" source="key" />
            <TextField label="Name" source="name_th" />
            <TextField label="Discount" source="discount" />
            <TextField label="Qty" source="qty" />
            <DateField label="Start Date" source="start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <DateField label="End Date" source="end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <EditButton />
        </Datagrid>
    </List>
);

export default PromoCodeList
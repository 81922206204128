import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            // source="prov_id._id"
                            source={props.isCreate ? "prov_id" : "prov_id._id"}
                            reference="providerlights"
                            fullWidth>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                        {formData.sub_service && <ReferenceInput
                            label="Sub Service"
                            source={formData.sub_service && formData.sub_service._id ? "sub_service._id" : "sub_service._id"}
                            reference="subservices"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>}
                        {formData.sub_service_promo && <ReferenceInput
                            label="Sub Service Promo"
                            source={formData.sub_service_promo && formData.sub_service_promo._id ? "sub_service_promo._id" : "sub_service_promo._id"}
                            reference="subservicepromos"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>}
                        <ReferenceInput
                            label="Spec Type"
                            source={formData.spec_type && formData.spec_type._id ? "spec_type._id" : "spec_type._id"}
                            reference="spectypes"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        {/* <BooleanInput label="Visible" source="visible" /> */}
    </SimpleForm>
);

export default Form;
const CHANGE_AIR_CLEANING_NOTIFICATION = 'CHANGE_AIR_CLEANING_NOTIFICATION';

export const changeAirCleaningNotification = notification => ({
    type: CHANGE_AIR_CLEANING_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_AIR_CLEANING_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, DateField, NumberField, BooleanField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Key" source="key_contains" />
    </Filter>
)

const VoucherList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Key" source="key" />
            <NumberField label="Price" source="price" options={{ style: 'currency', currency: 'THB' }} />
            <NumberField label="Wallet" source="wallet" />
            <DateField label="Start Date" source="start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <DateField label="End Date" source="end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <BooleanField label="Used" source="used" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
        </Datagrid>
    </List>
);

export default VoucherList
import React from 'react';
import { SimpleForm, TextInput, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator, NumberInput, FormDataConsumer } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';
import * as R from 'ramda'
import { required } from '../../services/validate'

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Main Service"
                            source={props.isCreate ? "main_service" : "main_service._id"}
                            reference="mainservices"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Service Group"
                            source="service_group._id"
                            reference="servicegroups"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Provider"
                            source={props.isCreate ? "prov_id" : "prov_id._id"}
                            reference="providerlights"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        {!props.isCreate && <FormDataConsumer>
            {({ formData, className, ...rest }) => {
                return <ArrayInput source="cost_sub_details" label="Cost Sub Detail" {...rest} fullWidth>
                    <SimpleFormIterator>
                        <CostSubDetail formData={formData} />
                    </SimpleFormIterator>
                </ArrayInput>
            }}
        </FormDataConsumer>}
    </SimpleForm>
);

const CostSubDetail = props => {
    const { formData, source } = props;
    return (
        <div>
            <ReferenceInput
                label="Sub service"
                source={`${source}.sub_service`}
                reference="subservices"
                fullWidth
                validate={[required()]}
                filter={{ main_service: formData.main_service._id }}>
                <SelectInput optionText="name_th" />
            </ReferenceInput>
            <NumberInput source={`${source}.cost`} label="Cost" validate={[required()]} />
        </div>
    );
};

export default Form;
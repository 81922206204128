import { queue } from 'async'
import { path } from 'ramda'
import API from '../configs/API'
import storage from './storage'

const uploadService = (file, field) => new Promise((resolve, reject) => {
    // if (typeof file === "string") resolve({ url: file })
    // else {
    const token = storage.load("token");
    const formData = new FormData();
    const fecthOption = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    if (file && Array.isArray(file) && file.length > 1) {
        const arrFiles = []
        const q = queue((task, cb) => {
            if (!task.rawFile && !field) {
                arrFiles.push(task)
                cb()
            } else {
                const rawFile = field ? task[field].rawFile : task.rawFile
                if (rawFile === undefined) {
                    arrFiles.push(task)
                    cb()
                } else {
                    const formData = new FormData()
                    formData.append('files', rawFile);
                    fecthOption.body = formData
                    fetch(`${API}/upload`, fecthOption)
                        .then(response => response.json())
                        .then(responseJson => {
                            if (field) {
                                task[field] = responseJson[0]
                                arrFiles.push(task)
                            } else {
                                arrFiles.push(responseJson[0])
                            }
                            cb()
                        })
                }
            }
        })
        q.drain(() => resolve(arrFiles));
        q.push(file);
    } else {
        if (!path(['rawFile'], file[0]) && !field) {
            resolve(file[0])
        }
        else if (field && !path([`${field}`, 'rawFile'], file[0])) {
            resolve(file)
        }
        else {
            const rawFile = field ? file[0][field].rawFile : file[0].rawFile
            formData.append('files', rawFile);
            fecthOption.body = formData
            fetch(`${API}/upload`, fecthOption)
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson === true) resolve(null);
                    else {
                        if (field) {
                            file[0][field] = responseJson[0]
                            resolve(file)
                        } else {
                            resolve(responseJson[0])
                        }
                    };
                })
        }
    }

}
)

export default uploadService
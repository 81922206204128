const required = (message = 'Required') =>
    value => value || value === 0 ? undefined : message;

const requiredMin = (min) =>
    value => value >= min ? undefined : `The minimum is ${min}`;

const validateMin = (min) =>
    value => (value === undefined || value >= min) ? undefined : `The minimum is ${min}`;
const requiredTime = () =>
    value => /^([0-1]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])?$/.test(value) ? undefined : 'format invalid'

const requiredEmail = () =>
    value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase()) ? undefined : `format invalid`;

export { required, requiredMin, requiredTime, requiredEmail, validateMin }
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput, EditButton, ChipField, ReferenceField } from 'react-admin';

const MgmtSenderEmailList = props => (
    <List {...props} >
        <Datagrid rowClick="edit">
            <TextField source="email" />
            <EditButton />
        </Datagrid>
    </List>
);

export default MgmtSenderEmailList
import React from 'react';
import { SimpleForm, TextInput, NumberInput, FormDataConsumer, ReferenceInput, SelectInput, ReferenceField, TextField } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        {!props.isEdit && <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Provider"
                            source={formData.prov_id && formData.prov_id._id ? "prov_id._id" : "prov_id._id"}
                            reference="providerlights"
                            fullWidth>
                            <SelectInput optionText="company_name" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Main Service"
                            source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                            reference="mainservices"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>}
        {props.isEdit && <TextField label="Provider" source="prov_id.company_name" />}
        {props.isEdit && <TextField label="Main Service" source="main_service.name_th" />}
        <NumberInput source="gp" label="Market GP" fullWidth validate={[required()]} />
        <NumberInput source="gp_com" label="Selling GP" fullWidth validate={[required()]} />
        <NumberInput source="app_fee" label="App fee" fullWidth />
    </SimpleForm>
);

export default Form;
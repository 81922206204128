import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, NumberField, SelectInput } from 'react-admin';

const methodChoices = [
    { method: 'Credit Card' },
    { method: 'PromptPay' },
    { method: 'Rabbit LINE Pay' },
];

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="method" choices={methodChoices} optionText="method" optionValue="method" />
    </Filter>
)

const ProviderPaymentList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="gateway" />
            <TextField source="config_table" />
            <TextField source="method" />
            <NumberField source="mdr" />
            {/* <ChipField label="Food type" source="food_type.name_th" /> */}
            <EditButton />
        </Datagrid>
    </List>
);

export default ProviderPaymentList
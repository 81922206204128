import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';


const mgmtPredayList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Main service" source="main_service.name_th" />
            <TextField label="Normal day" source="normal_day" />
            <TextField label="Special holiday" source="special_holiday" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default mgmtPredayList
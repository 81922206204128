import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ReferenceField, NumberField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Order Id" source="order_id_contains" />
    </Filter>
)

const OrderPromoList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Order Ref" source="order_id._id" reference="orderservices">
                <TextField source="ref" />
            </ReferenceField>
            <ReferenceField label="Sub Service" source="sub_service_promo._id" reference="subservicepromos">
                <TextField source="name_th" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default OrderPromoList
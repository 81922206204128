import React from 'react';
import { SimpleForm, NumberInput, BooleanInput, ReferenceInput, SelectInput, FormDataConsumer } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Subservices"
                        source={formData.sub_service && formData.sub_service._id ? "sub_service._id" : "sub_service._id"}
                        reference="subservices"
                        fullWidth
                        filter={{ main_service: SERVICE_TYPE.REGIS_NURSE }}
                    >
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="price" validate={[required()]} />
        <NumberInput source="hours" />
        <BooleanInput label="Visible" source="visible" />
        <BooleanInput label="Is visit nurse" source="is_visit_nurz" />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Third party" source="third_party._id" reference="thirdparties">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Internal app" source="internal_app._id" reference="internalapps">
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
)

const mgmtComList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Comnission Partner" source="com_partner" />
            <TextField label="Comnission Vender" source="com_vender" />
            <ReferenceField label="Third party" source="third_party._id" reference="thirdparties">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Internal app" source="internal_app._id" reference="internalapps">
                <TextField source="name" />
            </ReferenceField>
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default mgmtComList
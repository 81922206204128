import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <NumberInput source="com_partner" label="Commission Partner" fullWidth validate={[required()]} />
        <NumberInput source="com_vender" label="Commission Vender" fullWidth validate={[required()]} />

        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Third party"
                            source={formData.third_party && formData.third_party._id ? "third_party._id" : "third_party._id"}
                            reference="thirdparties"
                            fullWidth>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Internal App"
                            source={formData.internal_app && formData.internal_app._id ? "internal_app._id" : "internal_app._id"}
                            reference="internalapps"
                            fullWidth>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
    </SimpleForm>
);

export default Form;
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ReferenceInput, ReferenceField, SelectInput, ChipField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Company name" source="prov_id.company_name_contains" />
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <ReferenceInput label="Main Service" source="main_service._id" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const ShippingTagList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providerlights">
                <TextField source="company_name" />
            </ReferenceField>
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <ChipField label="Shipping type" source="shipping_type.name_th" />
            {/* <ChipField label="Main service" source="main_service.name_th" />
            <ChipField label="Provider" source="prov_id.company_name" /> */}
            {/* <TextField label="Condition" source="text_th" />
            <BooleanField label="Visible" source="visible" /> */}
            <EditButton />
        </Datagrid>
    </List>
);

export default ShippingTagList
const CHANGE_COUNT_ORDER_COMPLETE = 'CHANGE_COUNT_ORDER_COMPLETE';

export const changeCountOrderComplete = notification => ({
    type: CHANGE_COUNT_ORDER_COMPLETE,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_COUNT_ORDER_COMPLETE) {
        return payload;
    }
    return previousState;
};

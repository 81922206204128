const CHANGE_COUNT_ORDER_REFUND = 'CHANGE_COUNT_ORDER_REFUND';

export const changeCountOrderRefund = notification => ({
    type: CHANGE_COUNT_ORDER_REFUND,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_COUNT_ORDER_REFUND) {
        return payload;
    }
    return previousState;
};

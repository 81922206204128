import React from 'react';
import { Edit } from 'react-admin';
import Form from './Form'

const MgmtComimgSoonEdit = (props) => (
    <Edit {...props}>
        <Form />
    </Edit>
);

export default MgmtComimgSoonEdit 
import React from 'react';
import { SimpleForm, FormDataConsumer, ReferenceInput, SelectInput, BooleanInput, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Acupuncture massage groups"
                            source={formData.acupuncture_massage_group && formData.acupuncture_massage_group._id ? "acupuncture_massage_group._id" : "acupuncture_massage_group._id"}
                            reference="acupuncturemassagegroups"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                        {formData.acupuncture_massage_group && formData.acupuncture_massage_group._id && <ReferenceInput
                            label="Acupuncture massage sub-groups"
                            source={formData.acupuncture_massage_subgroup && formData.acupuncture_massage_subgroup._id ? "acupuncture_massage_subgroup._id" : "acupuncture_massage_subgroup._id"}
                            reference="acupuncturemassagesubgroups"
                            fullWidth
                            validate={[required()]}
                            filter={{ acupuncture_massage_group: formData.acupuncture_massage_group && formData.acupuncture_massage_group._id }}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>}
                        {/* <ReferenceInput
                            label="Room size"
                            source={formData.room_size && formData.room_size._id ? "room_size._id" : "room_size._id"}
                            reference="roomsizes"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput> */}
                        <ReferenceInput
                            label="Subservices"
                            source={formData.sub_service && formData.sub_service._id ? "sub_service._id" : "sub_service._id"}
                            reference="subservices"
                            fullWidth
                            validate={[required()]}
                            filter={{ main_service: SERVICE_TYPE.MAID }}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="hours" label="Hours" fullWidth validate={[required()]} />
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
        <BooleanInput label="Tool include" source="tool_include" />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
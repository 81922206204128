import React, { Fragment } from 'react';
import {
    TabbedForm, FormTab, TextField, SelectField, DateField, NumberField, ReferenceField
} from 'react-admin';
const ordertopuptype = [
    { id: 0, name: 'on-process' },
    { id: 1, name: 'complete' },
];
const Form = ({ ...props }) => {
    return (
        <TabbedForm {...props}>
            <FormTab label="Detail">
                <TextField label="Ref" source="ref" />
                <NumberField label="Cost" source="cost" options={{ style: 'currency', currency: 'THB' }} />
                <NumberField label="Total" source="total" options={{ style: 'currency', currency: 'THB' }} />
                <SelectField source="status" choices={ordertopuptype} />
            </FormTab>
            <FormTab label="Customer">
                <TextField label="Firstname" source="client.firstname" />
                <TextField label="Lastname" source="client.lastname" />
                <TextField label="Tel" source="client.tel" />
            </FormTab>
            {props.record.topup_package && <FormTab label="Topup package">
                <TextField label="Name" source="topup_package.name_th" />
                <TextField label="Detail" source="topup_package.detail_th" />
                <NumberField label="Price" source="topup_package.price" options={{ style: 'currency', currency: 'THB' }} />
            </FormTab>}
            {props.record.promo_topup && <FormTab label="Promo topup">
                <TextField label="Name" source="promo_topup.name_th" />
                <TextField label="Detail" source="promo_topup.detail_th" />
                <NumberField label="Price" source="promo_topup.price" options={{ style: 'currency', currency: 'THB' }} />
                <ReferenceField label="Topup package" source="promo_topup.topup_package" reference="topuppackages">
                    <TextField source="name_th" />
                </ReferenceField>
                <DateField label="Start date" source="promo_topup.start_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                <DateField label="End date" source="promo_topup.end_date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            </FormTab>}
        </TabbedForm>
    )
};

export default Form;
import React from 'react';
import { SimpleForm, TextInput, BooleanInput, ImageInput, ImageField } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="acronym" label="Acronym" fullWidth validate={[required()]} />
        <ImageInput source="image" label="Image" accept="image/*">
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
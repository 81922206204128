import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, AutocompleteInput, EditButton } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_contains" />
    </Filter>
)
const ThirdPartyList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name" />
            <TextField label="Tel" source="tel" />
            <TextField label="Address" source="address" />
            <BooleanField label="Proved" source="proved" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ThirdPartyList
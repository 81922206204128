const OrderStatus = {
    Pending: 0,
    Paid: 1,
    Confirmed: 2,
    CheckIn: 3,
    Completed: 4,
    Cancelled: 5,
    Drop: 9,
};

export const RefundStatus = {
    Pending: 0,
    Completed: 1,
};

export default OrderStatus;

import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton } from 'react-admin';
import { ImportActions } from '../../layout/ImportActions';
import TowingCarModels from './TowingCarModels.csv';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)

const TowingCarModelList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}
        actions={<ImportActions title={'Import towing car model by CSV'} resource="towingcarmodels" example={TowingCarModels} />}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <EditButton />
        </Datagrid>
    </List>
);

export default TowingCarModelList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput, EditButton, ChipField, ReferenceField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Option type" source="name_th_contains" />
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ShippingConditionList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providerlights">
                <TextField source="company_name" />
            </ReferenceField>
            <ChipField label="Option type" source="name_th" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ShippingConditionList
import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import { UserMenu } from 'react-admin';
import AirCleaningIcon from "@material-ui/icons/Cloud";
import MaidIcon from "@material-ui/icons/Home";
import AirportTransferIcon from "@material-ui/icons/DirectionsCar";
import TowingCarIcon from "@material-ui/icons/Notifications";
import RepairmanIcon from "@material-ui/icons/Build";
import LocalHospital from "@material-ui/icons/LocalHospital";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Opacity from "@material-ui/icons/Opacity";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from "react-router-dom";
import API from '../configs/API';
import { SERVICE_TYPE, SERVICE_NOTI_MINUTE } from '../services/service-type';
import { storage } from '../services';
import moment from 'moment';
import { changeAirCleaningNotification } from '../reducers/AirCleaningNotiReducer';
import { changeMaidNotification } from '../reducers/MaidNotiReducer';
import { changeAirportNotification } from '../reducers/AirportNotiReducer';
import { changeTowingCarNotification } from '../reducers/TowingCarNotiReducer';
import { changeRepairNotification } from '../reducers/RepairNotiReducer';
import { changeRegisNurseNotification } from '../reducers/RegisNurseNotiReducer';
import { changeHomeRegisNurseNotification } from '../reducers/HomeRegisNurseNotiReducer';
import { changeVirusNotification } from '../reducers/VirusNotiReducer';
import { changeOccupationalNotification } from '../reducers/OccupationalNotiReducer';
import { changePhysicalNotification } from '../reducers/PhysicalNotiReducer';
import { changeHealthCareNotification } from '../reducers/HealthCareNotiReducer';

const styles = theme =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            zIndex: 1300,
        },
        toolbar: {
            paddingRight: 24,
        },
        menuButton: {
            marginLeft: '0.5em',
            marginRight: '0.5em',
        },
        menuButtonIconClosed: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(0deg)',
        },
        menuButtonIconOpen: {
            transition: theme.transitions.create(['transform'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            transform: 'rotate(180deg)',
        },
        title: {
            flex: 1,
            // textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
        },
        loadingIndicator: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            zIndex: 1200,
            marginBottom: 16,
            marginTop: 16,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        notificationbutton: {
            color: "white"
        },
        logout: {
            color: 'white !important',
        },
    });

class AppBar extends React.Component {
    state = {}

    componentDidMount() {
        this.fetchAirCleanNoti();
        this.fetchMaidNoti();
        this.fetchAirportNoti();
        this.fetchTowingNoti();
        this.fetchRepairNoti();
        this.fetchRegisNoti();
        this.fetchVirusNoti();
        this.fetchOccupationalNoti();
        this.fetchPhysicalNoti();
        this.fetchHealthCareNoti();
    }

    fetchAirCleanNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.AIR_CLEANING}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.AIR_CLEANING, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeAirCleaningNotification(responseJson);
            }
        });
    }

    fetchMaidNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.MAID}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.MAID, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeMaidNotification(responseJson);
            }
        });
    }

    fetchAirportNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.AIRPORT_TRANSFER}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.AIRPORT_TRANSFER, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeAirportNotification(responseJson);
            }
        });
    }

    fetchTowingNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.TOWING_CAR}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.TOWING_CAR, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeTowingCarNotification(responseJson);
            }
        });
    }

    fetchRepairNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.EMERGENCY_REPAIRMAN}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.EMERGENCY_REPAIRMAN, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeRepairNotification(responseJson);
            }
        });
    }

    fetchHomeRegisNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.HOME_REGIS_NURSE}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.HOME_REGIS_NURSE, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeRegisNurseNotification(responseJson);
            }
        });
    }

    fetchRegisNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.REGIS_NURSE}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.REGIS_NURSE, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeRegisNurseNotification(responseJson);
            }
        });
    }

    fetchVirusNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.DISINFECTANT}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.DISINFECTANT, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeVirusNotification(responseJson);
            }
        });
    }

    fetchOccupationalNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.OCCUPATIONAL}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.OCCUPATIONAL, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeOccupationalNotification(responseJson);
            }
        });
    }

    fetchPhysicalNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.PHYSICAL}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.PHYSICAL, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changePhysicalNotification(responseJson);
            }
        });
    }

    fetchHealthCareNoti = () => {
        const token = storage.load("token");
        const toDay = new Date()
        fetch(`${API}/statustimes/count?order_id.main_service=${SERVICE_TYPE.HEALTH_CARE}&createdAt_gte=${moment(toDay)}&createdAt_lte=${moment(toDay).add(SERVICE_NOTI_MINUTE.HEALTH_CARE, 'minute')}`, {
            headers: { authorization: `Bearer ${token}` },
        }).then((response) => response.json()).then((responseJson) => {
            if (!responseJson.error) {
                this.props.changeHealthCareNotification(responseJson);
            }
        });
    }

    render() {
        const { children, classes, className, logo, logout, open,
            title, toggleSidebar, userMenu, width, AirCleningNoti,
            MaidNoti, AirportNoti, TowingNoti, RepairNoti, RegisNoti, VirusNoti, HomeRegisNoti,
            OccupationalNoti, PhysicalNoti, HealthCareNoti, ...rest } = this.props
        const toDay = new Date()
        return (
            <MuiAppBar
                className={className}
                color="secondary"
                position="absolute"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    // variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSidebar}
                        className={classNames(classes.menuButton)}
                    >
                        <MenuIcon
                            classes={{
                                root: open
                                    ? classes.menuButtonIconOpen
                                    : classes.menuButtonIconClosed,
                            }}
                        />
                    </IconButton>
                    {Children.count(children) === 0 ? (
                        <Typography
                            variant="title"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                            children
                        )}
                    <div>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.HEALTH_CARE}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.HEALTH_CARE, 'minute')}"}`} >
                            <Tooltip title="Health care">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <PersonAdd className={classNames(classes.notificationbutton)} />
                                    {HealthCareNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {HealthCareNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.PHYSICAL}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.PHYSICAL, 'minute')}"}`} >
                            <Tooltip title="Physical therapy">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <PersonAdd className={classNames(classes.notificationbutton)} />
                                    {PhysicalNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {PhysicalNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.OCCUPATIONAL}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.OCCUPATIONAL, 'minute')}"}`} >
                            <Tooltip title="Occupational therapy">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <PersonAdd className={classNames(classes.notificationbutton)} />
                                    {OccupationalNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {OccupationalNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.DISINFECTANT}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.DISINFECTANT, 'minute')}"}`} >
                            <Tooltip title="Disinfectant">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <Opacity className={classNames(classes.notificationbutton)} />
                                    {VirusNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {VirusNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.REGIS_NURSE}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.REGIS_NURSE, 'minute')}"}`} >
                            <Tooltip title="Registered Nurse">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <LocalHospital className={classNames(classes.notificationbutton)} />
                                    {RegisNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {RegisNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.HOME_REGIS_NURSE}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.HOME_REGIS_NURSE, 'minute')}"}`} >
                            <Tooltip title="Home Registered Nurse">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <LocalHospital className={classNames(classes.notificationbutton)} />
                                    {HomeRegisNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {HomeRegisNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.EMERGENCY_REPAIRMAN}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.EMERGENCY_REPAIRMAN, 'minute')}"}`} >
                            <Tooltip title="Emergency repairman">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <RepairmanIcon className={classNames(classes.notificationbutton)} />
                                    {RepairNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {RepairNoti}
                                            </span>
                                        </div>
                                        : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.TOWING_CAR}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.TOWING_CAR, 'minute')}"}`} >
                            <Tooltip title="Towing car">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <TowingCarIcon className={classNames(classes.notificationbutton)} />
                                    {TowingNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {TowingNoti}
                                            </span>
                                        </div> : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.AIRPORT_TRANSFER}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.AIRPORT_TRANSFER, 'minute')}"}`} >
                            <Tooltip title="Airport transfer">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <AirportTransferIcon className={classNames(classes.notificationbutton)} />
                                    {AirportNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {AirportNoti}
                                            </span>
                                        </div> : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.MAID}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.MAID, 'minute')}"}`} >
                            <Tooltip title="Maid">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <MaidIcon className={classNames(classes.notificationbutton)} />
                                    {MaidNoti ?
                                        <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {MaidNoti}
                                            </span>
                                        </div> : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link to={`/statustimes?filter={"order_id":{"main_service":"${SERVICE_TYPE.AIR_CLEANING}"},"createdAt_gte":"${moment(toDay)}","createdAt_lte":"${moment(toDay).add(SERVICE_NOTI_MINUTE.AIR_CLEANING, 'minute')}"}`} >
                            <Tooltip title="Air cleaning">
                                <IconButton style={{ float: "right", marginTop: 7 }}>
                                    <AirCleaningIcon className={classNames(classes.notificationbutton)} />
                                    {
                                        AirCleningNoti ? <div style={{
                                            width: 24, height: 24,
                                            position: "absolute",
                                            borderRadius: 12,
                                            background: "red",
                                            top: 0, right: 0
                                        }}>
                                            <span style={{
                                                fontSize: 12,
                                                color: "white",
                                                position: "absolute", left: 0, right: 0,
                                                lineHeight: "23px"
                                            }}>
                                                {AirCleningNoti}
                                            </span>
                                        </div>
                                            : null
                                    }
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </div>
                    {logout && cloneElement(userMenu, { logout })}
                </Toolbar>
            </MuiAppBar>
        );
    }
}

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <UserMenu />,
};
const mapStateToProps = state => ({
    AirCleningNoti: state.AirCleningNoti,
    MaidNoti: state.MaidNoti,
    AirportNoti: state.AirportNoti,
    TowingNoti: state.TowingNoti,
    RepairNoti: state.RepairNoti,
    HomeRegisNoti: state.HomeRegisNoti,
    RegisNoti: state.RegisNoti,
    VirusNoti: state.VirusNoti,
    OccupationalNoti: state.OccupationalNoti,
    PhysicalNoti: state.PhysicalNoti,
    HealthCareNoti: state.HealthCareNoti,
});

const enhance = compose(
    connect(
        mapStateToProps,
        {
            changeAirCleaningNotification,
            changeMaidNotification,
            changeAirportNotification,
            changeTowingCarNotification,
            changeRepairNotification,
            changeHomeRegisNurseNotification,
            changeRegisNurseNotification,
            changeVirusNotification,
            changeOccupationalNotification,
            changePhysicalNotification,
            changeHealthCareNotification,
            toggleSidebar: toggleSidebarAction,
        },
    ),
    withStyles(styles),
    withWidth()
);

export default enhance(AppBar);
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ReferenceInput, ReferenceField, SelectInput, ChipField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Merchant ID" source="merchant_id_contains" />
    </Filter>
)

const PaymentConfigMpayList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="project_code" />
            <TextField source="merchant_id" label="Merchant ID" />
            <TextField source="gateway" />
            <TextField source="secret_key" />
            <TextField source="secret_key_out" />
            <EditButton />
        </Datagrid>
    </List>
);

export default PaymentConfigMpayList
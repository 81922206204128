import React from 'react';
import {
    List, Datagrid, TextField, Filter, TextInput, EditButton
} from 'react-admin';
import { ImportActions } from '../../layout/ImportActions';
import TowingCarBrands from './TowingCarBrands.csv';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)

const TowingCarBrandList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}
        actions={<ImportActions title={'Import towing car brand by CSV'} resource="towingcarbrands" example={TowingCarBrands} />}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <EditButton />
        </Datagrid>
    </List >
);

export default TowingCarBrandList

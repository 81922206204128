import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, NumberField, ReferenceField, EditButton, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Company name" source="prov_id.company_name_contains" />
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <ReferenceInput label="Main Service" source="main_service._id" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const GpPriceList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providerlights">
                <TextField source="company_name" />
            </ReferenceField>
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <NumberField label="Market GP" source="gp" />
            <NumberField label="Selling GP" source="gp_com" />
            <NumberField label="App fee" source="app_fee" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default GpPriceList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)

const RoomSizeList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <EditButton />
        </Datagrid>
    </List>
);

export default RoomSizeList
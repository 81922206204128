const CHANGE_COUNT_ORDER_CANCEL = 'CHANGE_COUNT_ORDER_CANCEL';

export const changeCountOrderCancel = notification => ({
    type: CHANGE_COUNT_ORDER_CANCEL,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_COUNT_ORDER_CANCEL) {
        return payload;
    }
    return previousState;
};

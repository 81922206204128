import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceInput, SelectInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { required } from '../../services/validate'

const gateWayChoices = [
    { gateway: 'mPAY' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                console.log(formData)
                return (
                    <React.Fragment>
                        <ReferenceArrayInput
                            label="Provider"
                            // source="prov_id._id"
                            source="prov_ids"
                            reference="providerlights"
                            fullWidth>
                            <SelectArrayInput optionText="company_name" />
                        </ReferenceArrayInput>
                        <Box>
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="project_code" label="Project code" fullWidth validate={[required()]} />
                                    <TextInput source="secret_key" fullWidth validate={[required()]} />
                                    <TextInput source="qr_app_id" fullWidth validate={[required()]} />
                                    <TextInput source="qr_service_id_pp" fullWidth validate={[required()]} />
                                    <TextInput source="qr_terminal_id_pp" fullWidth validate={[required()]} />
                                    <TextInput source="qr_qrtype_pp" fullWidth validate={[required()]} />
                                    <TextInput source="sid" fullWidth validate={[required()]} />
                                    <TextInput source="gateway_api" fullWidth validate={[required()]} />
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <TextInput source="merchant_id" label="Merchant ID" fullWidth validate={[required()]} />
                                    <TextInput source="secret_key_out" fullWidth validate={[required()]} />
                                    <TextInput source="qr_app_secret" fullWidth validate={[required()]} />
                                    <TextInput source="qr_service_id_rlp" fullWidth validate={[required()]} />
                                    <TextInput source="qr_terminal_id_rlp" fullWidth validate={[required()]} />
                                    <TextInput source="qr_qrtype_rlp" fullWidth validate={[required()]} />
                                    <SelectInput source="gateway" choices={gateWayChoices} optionText="gateway" optionValue="gateway" fullWidth validate={[required()]} />
                                    <TextInput source="qr_gateway_api" fullWidth validate={[required()]} />
                                </Box>
                            </Box>
                        </Box>
                        {/* <ReferenceInput
                            label="Food Type"
                            source={formData.food_type && formData.food_type._id ? "food_type._id" : "food_type._id"}
                            reference="foodtypes"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput> */}
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <BooleanInput label="Is default" source="is_default" />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { queue } from 'async'
import storage from './storage';
import API from '../configs/API';
import { MAIN_SERVICE } from './service-type';
import TowingCarBrands from './TowingCarBrands';

const uploadExcel = (file, resource, setLoading) => new Promise((resolve, reject) => {
    const token = storage.load("token");
    const fecthOption = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    }
    if (file && Array.isArray(file)) {
        setLoading(true)
        if (resource === 'promocodes') {
            const arr = formatData(resource, file);
            const datas = { 'datas': arr };
            fecthOption.body = JSON.stringify(datas);
            fetch(`${API}/${resource}/insert-many`, fecthOption)
                .then(() => {
                    setLoading(false)
                })
        } else {
            const q = queue((task, cb) => {
                const arr = formatData(resource, task);
                fecthOption.body = JSON.stringify(arr);
                fetch(`${API}/${resource}`, fecthOption)
                    .then(() => {
                        cb()
                    })
            }, 2)
            q.drain(() => setLoading(false));
            q.push(file);
        }
    }
},
)

export { uploadExcel };

const formatData = (resource, file) => {
    switch (resource) {
        case 'towingcarbrands':
            const arrtowingcarbrands = {
                name_th: file[0],
                name_en: file[0],
                name_zh: file[0],
                name_ja: file[0],
                visible: true,
                is_other: file[1] === 'no' ? false : true,
            }
            return arrtowingcarbrands
            break;
        case 'towingcarmodels':
            const arrtowingcarmodels = {
                name_th: file[1],
                name_en: file[1],
                name_zh: file[1],
                name_ja: file[1],
                // towing_car_brand: TowingCarBrands[file[0]],
                visible: true,
                is_other: file[2] === 'no' ? false : true,
            }
            return arrtowingcarmodels
            break;
        case 'carbrands':
            const arrcarbrands = {
                name_th: file[0],
                name_en: file[0],
                name_zh: file[0],
                name_ja: file[0],
                // car_models: '',
                visible: true,
                is_other: file[2] === 'no' ? false : true,
            }
            return arrcarbrands
            break;
        case 'carmodels':
            const arrcarmodels = {
                name_th: file[0],
                name_en: file[0],
                name_zh: file[0],
                name_ja: file[0],
                // car_brand: '',
                // car_type:'',
                // engine_sizes: '',
                premium_car: file[4] === 'no' ? false : true,
                is_other: file[5] === 'no' ? false : true,
                visible: true,
            }
            return arrcarmodels
            break;
        case 'promocodes':
            const arrpromocode = file.map((each, i) => ({
                promo_code_type: each[0],
                min_purchase: parseInt(each[1]),
                key: each[2],
                name_th: each[3],
                name_en: each[4],
                name_zh: each[4],
                name_ja: each[4],
                discount: parseInt(each[5]),
                qty: parseInt(each[6]),
                start_date: each[7],
                end_date: each[8],
                main_service: MAIN_SERVICE[each[9]],
                prov_ids: each[10].split(/\s*(?:,|$)\s*/),
                visible: true,
            }))
            return arrpromocode
            break;
        default:
            break;
    }
}
import React from 'react';
import { SimpleForm, TextInput, BooleanInput, ReferenceField, TextField } from 'react-admin';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <ReferenceField label="Order Ref" source="order_id.id" reference="orderservices">
            <TextField source="ref" />
        </ReferenceField>
        <ReferenceField label="Sub Service" source="sub_service_promo.id" reference="subservicepromos">
            <TextField source="name_th" />
        </ReferenceField>
    </SimpleForm>
);

export default Form;
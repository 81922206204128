import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, AutocompleteInput } from 'react-admin';
import { required } from '../../services/validate'
import { get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <AutocompleteInput source={`province`} label="Province" choices={provinces} optionText="label" optionValue="value" fullWidth />
        <NumberInput source="extra" label="Extra" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { SimpleForm, NumberInput, TextInput, BooleanInput } from 'react-admin';
import { required, requiredTime } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <TextInput label="Start time" source="start_time" validate={[requiredTime()]} placeholder="00:00:00" />
        <TextInput label="End time" source="end_time" validate={[requiredTime()]} placeholder="00:00:00" />
        <NumberInput label="Start time" source="start_time_s" validate={[required()]} />
        <NumberInput label="End time" source="end_time_s" validate={[required()]} />
        <NumberInput label="Price per km" source="price_per_km" validate={[required()]} />
        <NumberInput label="Startup price" source="startup_price" validate={[required()]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
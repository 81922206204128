import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, EditButton } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Acc name" source="acc_name_contains" />
    </Filter>
)

const BankTransferConfigList = props => (
    <List {...props} filters={<ListFilter />} >
        <Datagrid rowClick="edit">
            <TextField label="Acc name" source="acc_name" />
            <ReferenceField label="Bank" source="bank._id" reference="banks">
                <TextField source="name_th" />
            </ReferenceField>
            <TextField label="Acc number" source="acc_number" />
            <EditButton />
        </Datagrid>
    </List>
);

export default BankTransferConfigList
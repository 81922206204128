const CHANGE_HEALTH_CARE_NOTIFICATION = 'CHANGE_HEALTH_CARE_NOTIFICATION';

export const changeHealthCareNotification = notification => ({
    type: CHANGE_HEALTH_CARE_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_HEALTH_CARE_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

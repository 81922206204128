import React from 'react';
import {
    TabbedForm, FormTab, TextInput, BooleanInput, SelectInput, FormDataConsumer, PasswordInput, ImageInput, ImageField, Datagrid, TextField,
    ReferenceManyField, SelectField, ReferenceField, DateField, NumberField, Pagination, ArrayField,
    FunctionField, List
} from 'react-admin';
import { required } from '../../services/validate'
import { invertObj } from 'ramda';
import OrderStatus from '../../services/OrderStatus';
import { findAddress } from '../../services/address/finder';

const Form = ({ ...props }) => {
    return (
        <TabbedForm {...props}>
            <FormTab label="Information">
                <TextField source="firstname" />
                <TextField source="lastname" />
                <TextField source="email" />
                <TextField source="tel" />
                <FunctionField label="Province" render={record => {
                    const address = findAddress({ province_code: record.province });
                    return `${address[0] ? address[0].province_th : record.province}`
                }}
                />
                <FunctionField label="District" render={record => {
                    const address = findAddress({ amphoe_code: record.district });
                    return `${address[0] ? address[0].amphoe_th : record.district}`
                }}
                />
                <TextField source="zipcode" />
                <BooleanInput source="confirmed" initialValue={false} />
                <BooleanInput source="blocked" initialValue={false} />
            </FormTab>
            <FormTab label="Order">
                <ReferenceManyField
                    label="Order"
                    reference="orderresponsibles"
                    filter={{
                        emp_id: props.record._id,
                    }}
                    fullWidth>
                    <List bulkActionButtons={false} exporter={false}>
                        <Datagrid>
                            <TextField source="order_id.ref" label="Order" />
                            <ReferenceField label="Service" source="order_id.main_service" reference="mainservices">
                                <TextField source="name_th" />
                            </ReferenceField>
                            <DateField source="order_id.createdAt" label="Created at" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                            <DateField source="order_id.date_time" label="Appointment date & time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
                            <FunctionField label="Status" render={record => {
                                const status = invertObj(OrderStatus)[record.order_id.status];
                                return status
                            }}
                            />
                        </Datagrid>
                    </List>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    )
};

export default Form;
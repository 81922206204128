import React from 'react';
import { SimpleForm, TextInput, BooleanInput, NumberInput, SelectInput, FormDataConsumer, ReferenceInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { required, validateMin } from '../../services/validate'
import { DateTimeInput } from 'react-admin-date-inputs';

const choices = [
    { name: 'value', },
    { name: 'percent', },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceArrayInput
                            label="Provider"
                            // source="prov_id._id"
                            source="prov_ids"
                            reference="providerlights"
                            fullWidth>
                            <SelectArrayInput optionText="company_name" />
                        </ReferenceArrayInput>
                        <ReferenceInput
                            label="Main Service"
                            source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                            reference="mainservices"
                            filter={{
                                visible: true,
                            }}
                            fullWidth>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                        {formData.main_service && <ReferenceArrayInput
                            label="Sub service"
                            source="sub_services"
                            reference="subservices"
                            filter={{ main_service: formData.main_service && formData.main_service._id }}
                            fullWidth>
                            <SelectArrayInput optionText="name_th" />
                        </ReferenceArrayInput>}
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <TextInput source="key" label="Key" fullWidth validate={[required()]} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <SelectInput source="promo_code_type" choices={choices} label="Promo code type" optionText="name" optionValue="name" fullWidth validate={[required()]} />
        <NumberInput source="discount" label="Discount" fullWidth validate={[required()]} />
        <NumberInput source="qty" label="Qty" fullWidth validate={[required()]} />
        <NumberInput source="min_purchase" label="Min Purchase" fullWidth validate={[required()]} />
        <NumberInput source="x_times_per_user" min="1" label="Times per user" fullWidth validate={validateMin(1)} />
        <DateTimeInput source="start_date" label="Start date" fullWidth validate={[required()]} options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <DateTimeInput source="end_date" label="End date" fullWidth validate={[required()]} options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
import {
	CREATE,
	UPDATE,
	DELETE,
} from 'react-admin';
import * as R from 'ramda'
import uploadService from './uploadService'
import storage from './storage'
import API from '../configs/API'
import { SERVICE_TYPE } from './service-type';

const resourceName = ['banners', 'banks', 'additionalservices', 'mainservices',
	'servicegroups', 'topuppackages', 'promotopups', 'promoservices', 'vouchers']

const requesthandler = request => async (type, resource, params) => {
	if ((type === CREATE || type === UPDATE) && (resourceName.find((each) => resource === each))) {
		const { image } = params.data;
		if (image) {
			const newImage = await uploadService([image]);
			params.data.image = newImage._id
		} else {
			params.data.image = null
		}
	}
	else if ((type === CREATE || type === UPDATE) && resource === 'banktransferconfigs') {
		const { acc_file } = params.data;
		if (acc_file) {
			const newImage = await uploadService([acc_file]);
			params.data.acc_file = newImage._id
		} else {
			params.data.acc_file = null
		}
	}
	else if ((type === CREATE || type === UPDATE) && resource === 'cars') {
		const { attachs } = params.data;
		if (attachs) {
			const newImage = await uploadService([attachs]);
			params.data.attachs = newImage._id
		} else {
			params.data.attachs = null
		}
	}
	else if ((type === CREATE || type === UPDATE) && resource === 'mgmtcustomproviderinfos') {
		const { logo } = params.data;
		if (logo) {
			const newImage = await uploadService([logo]);
			params.data.logo = newImage._id
		} else {
			params.data.logo = null
		}
	}
	else if ((type === CREATE || type === UPDATE) && (resource === 'internalapps' || resource === 'thirdparties' || resource === 'vendors')) {
		const { attachs, bank_acc_file } = params.data;
		if (attachs && bank_acc_file) {
			const newImage = await uploadService([attachs]);
			params.data.attachs = newImage._id
			const newImage1 = await uploadService([bank_acc_file]);
			params.data.bank_acc_file = newImage1._id
		} else if (attachs) {
			const newImage = await uploadService([attachs]);
			params.data.attachs = newImage._id
		} else if (bank_acc_file) {
			const newImage = await uploadService([bank_acc_file]);
			params.data.bank_acc_file = newImage._id
		} else {
			params.data.attachs = null
			params.data.bank_acc_file = null
		}
	}
	else if (type === UPDATE && resource === 'costsubs') {
		const { id, previousData } = params
		const { cost_sub_details, ...costsubs } = params.data
		params.data = { ...costsubs }
		const difference = R.symmetricDifferenceWith(R.eqBy(R.prop('_id')), cost_sub_details, previousData.cost_sub_details);
		const create = cost_sub_details.filter(c => !c._id).map(c => ({ ...c, cost_sub: id }))
		const del = difference.filter(d => d._id)
		const update = cost_sub_details.filter(u => u._id)
		Promise.all(R.concat(
			create.map(c => FETCH_CREATE('costsubdetails', c)),
			del.map(d => FETCH_DELETE('costsubdetails', d._id)),
			update.map(u => FETCH_PUT('costsubdetails', u._id, u))
		));
	}
	else if (type === UPDATE && resource === 'promoservices') {
		const { id, previousData } = params
		const { promo_items, ...promoservices } = params.data
		params.data = { ...promoservices }
		const difference = R.symmetricDifferenceWith(R.eqBy(R.prop('_id')), promo_items, previousData.promo_items);
		const create = promo_items.filter(c => !c._id).map(c => ({ ...c, promo_service: id }))
		const del = difference.filter(d => d._id)
		const update = promo_items.filter(u => u._id)
		Promise.all(R.concat(
			create.map(c => FETCH_CREATE('promoitems', c)),
			del.map(d => FETCH_DELETE('promoitems', d._id)),
			update.map(u => FETCH_PUT('promoitems', u._id, u))
		));
	}
	else if (type === UPDATE && resource === 'subservicepromo') {
		const { images } = params.data
		if (images.length > 1) {
			const newImage = await uploadService(images);
			params.data.images = newImage.map((each) => each._id)
		} else if (images.length === 1) {
			const newImage = await uploadService(images);
			params.data.images = [newImage._id]
		} else {
			params.data.images = null
		}
	}
	else if ((type === CREATE || type === UPDATE) && resource === 'subservices') {
		let result = [];
		let list = [];
		const { id, previousData } = params
		const { car_areas, images, main_service, ...subservices } = params.data
		if (main_service._id === SERVICE_TYPE.AIRPORT_TRANSFER || main_service._id === SERVICE_TYPE.TOWING_CAR || main_service._id === SERVICE_TYPE.EMERGENCY_REPAIRMAN) {
			const listCarAreas = car_areas[0].car_areas || [];
			for (let i = 0; i < car_areas.length; i++) {
				list = list.concat(car_areas[i].car_areas)
			}
			const carArea = car_areas.map(element =>
				element.district.map(district =>
				({
					_id: (!!listCarAreas && listCarAreas.find(f => f.district === district)) && list.find(f => f.district === district)._id,
					province: element.province,
					district: district,
				})
				));
			for (let i = 0; i < carArea.length; i++) {
				result = result.concat(carArea[i])
			}
			// console.log(result)
			params.data = { ...subservices }
			const difference = R.symmetricDifferenceWith(R.eqBy(R.prop('_id')), result, list);
			const create = result.filter(c => !c._id).map(c => ({ province: c.province, district: c.district, sub_service: id }))
			const del = difference.filter(d => d._id)
			// const update = result.filter(u => u._id)
			// console.log(del)
			// update.map(u => FETCH_PUT('carareas', u._id, u)),
			Promise.all(R.concat(
				create.map(c => FETCH_CREATE('carareas', c)),
				del.map(d => FETCH_DELETE('carareas', d._id)),
			));
		}
		if (images.length > 1) {
			const newImage = await uploadService(images);
			params.data.images = newImage.map((each) => each._id)
		} else if (images.length === 1) {
			const newImage = await uploadService(images);
			params.data.images = [newImage._id]
		} else {
			params.data.images = null
		}
	}
	return request(type, resource, params);
};
const token = storage.load("token");
const headers = () => ({ 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' })
const FETCH_CREATE = (resource, body) => fetch(`${API}/${resource}`, { method: 'POST', headers: headers(), body: JSON.stringify(body) })
const FETCH_DELETE = (resource, id) => fetch(`${API}/${resource}/${id}`, { method: 'DELETE', headers: headers() })
const FETCH_PUT = (resource, id, body) => fetch(`${API}/${resource}/${id}`, { method: 'PUT', headers: headers(), body: JSON.stringify(body) })


export default requesthandler
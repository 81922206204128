import React from 'react';
import { SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput, FormDataConsumer, ReferenceInput, SelectInput, ImageInput, ImageField } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceArrayInput
                            label="Provider"
                            // source="prov_id._id"
                            source="prov_ids"
                            reference="providerlights"
                            fullWidth>
                            <SelectArrayInput optionText="company_name" />
                        </ReferenceArrayInput>
                        <ReferenceInput
                            label="Bank"
                            source={formData.bank && formData.bank._id ? "bank._id" : "bank._id"}
                            reference="banks"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <TextInput source="acc_name" label="Acc name" fullWidth validate={[required()]} />
        <TextInput source="acc_number" label="Acc number" fullWidth validate={[required()]} />
        <ImageInput source="acc_file" label="Acc file" accept="image/*">
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
    </SimpleForm>
);

export default Form;
const CHANGE_COUNT_ORDER_CUSTOMER_CANCEL = 'CHANGE_COUNT_ORDER_CUSTOMER_CANCEL';

export const changeCountOrderCustomerCancel = notification => ({
    type: CHANGE_COUNT_ORDER_CUSTOMER_CANCEL,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_COUNT_ORDER_CUSTOMER_CANCEL) {
        return payload;
    }
    return previousState;
};

import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceArrayInput, SelectArrayInput, TextInput, ReferenceInput, SelectInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceArrayInput
                            label="Provider"
                            // source="prov_id._id"
                            source="prov_ids"
                            reference="providerlights"
                            fullWidth
                            validate={[required()]}>
                            <SelectArrayInput optionText="company_name" />
                        </ReferenceArrayInput>
                        <ArrayInput source="emails" validate={[required()]}>
                            <SimpleFormIterator>
                                <TextInput source="email" type="email" label="email" validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
    </SimpleForm>
);

export default Form;
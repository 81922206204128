import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput, EditButton } from 'react-admin';
import CarModel from './CarModel.csv';
import { ImportActions } from '../../layout/ImportActions';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Car model" source="name_th_contains" />
        <BooleanInput label="Premium car" source="premium_car" />
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const CarModelList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}
        actions={<ImportActions title={'Import car model by CSV'} resource="carmodels" example={CarModel} />}>
        <Datagrid rowClick="edit">
            <TextField label="Car model" source="name_th" />
            <BooleanField label="Premium car" source="premium_car" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default CarModelList
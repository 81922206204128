import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, ChipField, BooleanField, BooleanInput, EditButton } from 'react-admin';

const AcupunctureMassageSubList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name TH" source="name_th" />
            <TextField label="Name EN" source="name_en" />
            <EditButton />
        </Datagrid>
    </List>
);

export default AcupunctureMassageSubList
import React from 'react';
import { SimpleForm, TextInput, BooleanInput, FormDataConsumer, NumberInput, SelectInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { Box } from '@material-ui/core';
import { required } from '../../services/validate'

const gateWayChoices = [
    { gateway: 'mPAY' },
    { gateway: 'Bank' },
];

const configTableChoices = [
    { table: 'paymentgatewayconfigmpay' },
    { table: 'banktransferconfig' },
];

const methodChoices = [
    { method: 'Credit Card' },
    { method: 'PromptPay' },
    { method: 'Rabbit LINE Pay' },
    { method: 'Bank Transfer' },
];

const mdrTypeChoices = [
    { type: 'value' },
    { type: 'percent' },
];

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceArrayInput
                            label="Provider"
                            // source="prov_id._id"
                            source="prov_ids"
                            reference="providerlights"
                            fullWidth>
                            <SelectArrayInput optionText="company_name" />
                        </ReferenceArrayInput>
                        <Box>
                            <Box display="flex">
                                <Box flex={1} mr="0.5em">
                                    <SelectInput source="gateway" choices={gateWayChoices} optionText="gateway" optionValue="gateway" fullWidth validate={[required()]} />
                                    <SelectInput source="method" choices={methodChoices} optionText="method" optionValue="method" fullWidth validate={[required()]} />
                                    <NumberInput source="mdr" min="0" fullWidth validate={[required()]} />
                                    <BooleanInput label="Can refunded as wallet" source="can_refunded_as_wallet" />
                                </Box>
                                <Box flex={1} mr="0.5em">
                                    <SelectInput source="config_table" choices={configTableChoices} optionText="table" optionValue="table" fullWidth validate={[required()]} />
                                    <SelectInput source="mdr_type" choices={mdrTypeChoices} optionText="type" optionValue="type" fullWidth validate={[required()]} />
                                    <BooleanInput label="Is appboii payment" source="is_appboii_payment" />
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
    </SimpleForm>
);

export default Form;
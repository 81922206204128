import React from 'react';
import { Create } from 'react-admin';
import Form from './Form'

const ServiceSmsVipCreate = (props) => (
    <Create {...props}>
        <Form />
    </Create>
);

export default ServiceSmsVipCreate
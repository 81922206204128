import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, EditButton, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import { union } from 'ramda'
import { findAddress } from '../../services/address/finder';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Provider" source="prov_id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <ReferenceInput source="main_service" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const CostSubList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Provider" source="prov_id._id" reference="providerlights">
                <TextField source="company_name" />
            </ReferenceField>
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <ReferenceField label="Service group" source="service_group._id" reference="servicegroups">
                <TextField source="name_th" />
            </ReferenceField>
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default CostSubList
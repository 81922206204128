import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, NumberField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Main service" source="main_service.name_th_contains" />
    </Filter>
)

const PerKmsList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Main service" source="main_service.name_th" />
            <NumberField label="Km" source="km" />
            <NumberField label="Price" source="price" options={{ style: 'currency', currency: 'THB' }} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default PerKmsList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, SelectField, SelectInput, EditButton } from 'react-admin';
import registype from './registype'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Username" source="username_contains" />
        <SelectInput source="regis_type" choices={registype} />
    </Filter>
)

const ListTable = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Username" source="username" />
            <TextField label="Email" source="email" />
            <TextField label="Provider" source="provider" />
            <SelectField source="regis_type" choices={registype} />
            <BooleanField label="Confirmed" source="confirmed" />
            <BooleanField label="Blocked" source="blocked" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ListTable
import React from 'react';
import * as R from 'ramda'
import { SimpleForm, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <React.Fragment>
                        <ReferenceInput
                            label="Service group"
                            source={formData.service_group && formData.service_group._id ? "service_group._id" : "service_group._id"}
                            reference="servicegroups"
                            fullWidth
                            validate={[required()]}>
                            <SelectInput optionText="name_th" />
                        </ReferenceInput>
                    </React.Fragment>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Main service"
                        source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                        reference="mainservices"
                        fullWidth
                        filter={{ service_group: { _id: formData.service_group && formData.service_group._id } }}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>

    </SimpleForm>
);

export default Form;
import React from 'react';
import { SimpleForm, TextInput, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { DateTimeInput } from 'react-admin-date-inputs';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <DateTimeInput source="date" label="Date" fullWidth validate={[required()]} options={{ format: 'dd/MM/yyy, HH:mm:ss', ampm: false, clearable: true }} />
        <TextInput source="name_th" label="Name TH" fullWidth validate={[required()]} />
        <TextInput source="name_en" label="Name EN" fullWidth validate={[required()]} />
        <TextInput source="name_ja" label="Name JA" fullWidth validate={[required()]} />
        <TextInput source="name_zh" label="Name ZH" fullWidth validate={[required()]} />
        <NumberInput source="extra" label="Extra" fullWidth validate={[required()]} />
    </SimpleForm>
);

export default Form;
import React from 'react';
import {
    List, Datagrid, TextField, Filter, TextInput, ReferenceField, SelectField, NumberField,
    ReferenceInput, SelectInput, DateField, EditButton
} from 'react-admin';
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { DateInput } from 'react-admin-date-inputs';
import orderstatustype from './orderstatustype'
import moment from 'moment';
import OrderStatus from '../../services/OrderStatus';
import { invertObj } from 'ramda';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Ref" source="ref_contains" />
        <ReferenceInput source="main_service" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Service group" source="service_group._id" reference="servicegroups">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
        <ReferenceInput label="Third Party" source="third_party._id" reference="thirdparties">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" choices={orderstatustype} />
        <DateInput label="Service date time more than" source="date_time_gte" options={{ format: 'dd/MM/yyyy' }} />
        <DateInput label="Service date time less than" source="date_time_lte" options={{ format: 'dd/MM/yyyy' }} />
    </Filter>
)

const exporter = (records, fetchRelatedRecords) => {
    // console.log(records)
    // records = records.filter((record, index) => record.status >= 4);
    Promise.all(records.map(async (record) => {
        let newRecord = {};
        return {
            ...newRecord,
            id: record._id,
            date_time: record.date_time,
            ref: record.ref,
            sub_total: record.sub_total,
            discount: record.discount,
            vat: record.vat,
            total: record.total,
            pay_more: record.pay_more,
            status: invertObj(OrderStatus)[record.status],
            service_date_time: moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss"),
            booking_date: moment(record.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
            firstname: record.client.firstname,
            lastname: record.client.lastname,
            gender: record.client.gender,
            location: !!record.location ? record.location.name : '',
            pickup_drops: !!record.pickup_drops[0] ? `${record.pickup_drops[0].from_name !== undefined ? `${record.pickup_drops[0].from_name} -` : ''} ${record.pickup_drops[0].to_name}` : '',
            main_service: record.main_service.name_th,
            service_group: record.service_group.name_th,
            promo_code: !!record.promo_code ? record.promo_code.name_th : '',
            promo_code_key: !!record.promo_code_key ? record.promo_code_key : '',
        };
    })).then(data => {
        const csv = convertToCSV({
            data,
            fields: ["id", "ref", "service_group", "main_service", "status", "firstname", "lastname", "gender", "location", "pickup_drops", "sub_total", "discount", "vat", "total", "pay_more", "service_date_time", "booking_date"]
        });
        csvDownload(csv, "orderservice");
    });


}
const csvDownload = (csv, filename) => {
    const fakeLink = document.createElement("a");
    fakeLink.style.display = "none";
    document.body.appendChild(fakeLink);
    const blob = new Blob(["\uFEFF" + csv], { type: "text/csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // Manage IE11+ & Edge
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
    } else {
        fakeLink.setAttribute("href", URL.createObjectURL(blob));
        fakeLink.setAttribute("download", `${filename}.csv`);
        fakeLink.click();
    }
};

const OrderServiceList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false} exporter={exporter}>
        <Datagrid rowClick="edit">
            <TextField label="Ref" source="ref" />
            <ReferenceField label="Main service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <ReferenceField label="Service group" source="service_group._id" reference="servicegroups">
                <TextField source="name_th" />
            </ReferenceField>
            <SelectField source="status" choices={orderstatustype} />
            <DateField label="Service date time" source="date_time" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <DateField label="Booking date" source="createdAt" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <NumberField source="total" options={{ style: 'currency', currency: 'THB' }} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default OrderServiceList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, DateField, EditButton } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
    </Filter>
)

const SpecialHolidayList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <TextField label="extra" source="extra" />
            <DateField label="Date" source="date" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }} />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default SpecialHolidayList
import React from 'react';
import { SimpleForm, FormDataConsumer, ReferenceInput, SelectInput, BooleanInput, NumberInput } from 'react-admin';
import { required } from '../../services/validate'
import { SERVICE_TYPE } from '../../services/service-type';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Air types"
                        source={formData.air_type && formData.air_type._id ? "air_type._id" : "air_type._id"}
                        reference="airtypes"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="BTU sizes"
                        source={formData.btu_size && formData.btu_size._id ? "btu_size._id" : "btu_size._id"}
                        reference="btusizes"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Subservices"
                        source={formData.sub_service && formData.sub_service._id ? "sub_service._id" : "sub_service._id"}
                        reference="subservices"
                        fullWidth
                        validate={[required()]}
                        filter={{ main_service: SERVICE_TYPE.AIR_CLEANING }}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <NumberInput source="price" label="Price" fullWidth validate={[required()]} />
        <NumberInput source="inventory" label="Inventory" fullWidth />
        <NumberInput source="inventory_noti" label="Inventory Noti" fullWidth />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
const CHANGE_REPAIR_NOTIFICATION = 'CHANGE_REPAIR_NOTIFICATION';

export const changeRepairNotification = notification => ({
    type: CHANGE_REPAIR_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_REPAIR_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

const CHANGE_VIRUS_NOTIFICATION = 'CHANGE_VIRUS_NOTIFICATION';

export const changeVirusNotification = notification => ({
    type: CHANGE_VIRUS_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_VIRUS_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

import React from 'react';
import { List, Datagrid, TextField, Filter, BooleanInput, EditButton, BooleanField, ReferenceInput, SelectInput, ReferenceField, NumberField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="sub_service" reference="subservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ServiceNurseList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Sub service" source="sub_service._id" reference="subservices">
                <TextField source="name_th" />
            </ReferenceField>
            <NumberField label="Price" source="price" options={{ style: 'currency', currency: 'THB' }} />
            <NumberField label="Hours" source="hours" />
            <BooleanField label="Visible" source="visible" />
            <BooleanField label="Is visit nurse" source="is_visit_nurz" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ServiceNurseList
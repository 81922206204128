import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, ReferenceField, NumberField } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Order Id" source="order_id_contains" />
    </Filter>
)

const OrderCashbackList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Firstname" source="client.firstname" />
            <TextField label="Lastname" source="client.lastname" />
            <TextField label="Tel" source="client.tel" />
        </Datagrid>
    </List>
);

export default OrderCashbackList
import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, FormDataConsumer, BooleanInput, ImageInput, ImageField, ReferenceInput, SelectInput } from 'react-admin';
import { required, requiredEmail } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Provider"
                        source={props.isCreate ? "provider" : "provider"}
                        reference="providerlights"
                        fullWidth>
                        <SelectInput optionText="company_name" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <TextInput source="logo_name" fullWidth />
        <TextInput source="tel" fullWidth validate={[required()]} />
        <TextInput source="tel_href" fullWidth validate={[required()]} />
        <TextInput type="email" source="email" label="Email" fullWidth validate={[requiredEmail()]} />
        <TextInput source="office_location" fullWidth validate={[required()]} />
        <TextInput source="office_location_en" fullWidth />
        <TextInput source="company_name" fullWidth validate={[required()]} />
        <TextInput source="company_name_en" fullWidth />
        <TextInput source="copyright" fullWidth />
        <TextInput source="tax_identification" fullWidth />
        <TextInput source="info_link" fullWidth />
        <TextInput source="info_link_href" fullWidth />
        <TextInput source="sms_sender_name" label="SMS sender name" fullWidth />
        <ImageInput source="logo" label="Logo" accept="image/*" validate={[required()]}>
            <ImageField source="fullUrl" title="name" />
        </ImageInput>
        <BooleanInput label="Can request tax invoice" source="can_request_tax_invoice" />
    </SimpleForm>
);

export default Form;
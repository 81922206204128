import React from 'react';
import { SimpleForm, BooleanInput, NumberInput, FormDataConsumer, ReferenceInput, SelectInput } from 'react-admin';

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="User"
                        source={formData.client ? "client" : "client"}
                        reference="users"
                        fullWidth
                        filter={{ regis_type: "client", confirmed: true }}>
                        <SelectInput optionText="client_id.firstname" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <BooleanInput label="Cashback All" source="cashback_all" />
        <NumberInput source="value" label="value" fullWidth />
    </SimpleForm>
);

export default Form;
import React from 'react';
import { List, Filter, SelectInput, Datagrid, TextField, ReferenceInput, ReferenceField, FunctionField } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import OrderStatus from '../../services/OrderStatus'
import moment from 'moment';
import { unparse as convertToCSV } from "papaparse/papaparse.min";


const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="main_service" reference="mainservices">
      <SelectInput optionText="name_th" />
    </ReferenceInput>
    <DateInput label="Start date" source="datetime_start" options={{ format: 'dd/MM/yyyy' }} />
    <DateInput label="End date" source="datetime_end" options={{ format: 'dd/MM/yyyy' }} />
  </Filter>
)

const AccountingList = props => (
  <List {...props} filters={<ListFilter />}
    exporter={exporter}
    filterDefaultValues={{ option: 2, status: OrderStatus.Completed }}
    bulkActionButtons={false}>
    <Datagrid>
      <TextField label="Order ID" source="ref" />
      <ReferenceField label="Provider" source="prov_id" reference="providerlights">
        <TextField source="company_name" />
      </ReferenceField>
      <ReferenceField label="Service" source="main_service" reference="mainservices">
        <TextField source="name_th" />
      </ReferenceField>
      <FunctionField label="Complete Date" render={record => {
        const { status_times } = record
        const complete_date = status_times.find(s => s.status === 4)
        return <span>{moment(complete_date.createdAt).format("D MMM YYYY HH:mm")}</span>
      }} />
      <FunctionField label="Amount" render={record => {
        const { orderaccounting } = record
        if (!orderaccounting) return <span></span>
        const { income } = orderaccounting
        return <span>{income.toLocaleString('th-TH', { style: 'currency', currency: 'THB' })}</span>
      }} />


    </Datagrid>
  </List>
);


const exporter = (records, fetchRelatedRecords) => {
  Promise.all(records.map(async (record) => {
    let newRecord = {};
    await fetchRelatedRecords(records, "prov_id", "providers").then(res => {
      newRecord = {
        ...newRecord,
        account_name: res[record.prov_id]?.account_name || res[record.prov_id]?.company_name,
        bank_acc: res[record.prov_id]?.bank_acc,
        bank: res[record.prov_id]?.bank?.acronym,
        tax_id: res[record.prov_id]?.tax_id,
        account_address: res[record.prov_id]?.account_address,
      };
    });
    return {
      ...newRecord,
      amount: record.orderaccounting?.income
    };
  })).then(data => {
    const csv = convertToCSV({
      data,
      fields: ["account_name", "bank_acc", "amount", "bank", "tax_id", "account_address"]
    });
    csvDownload(csv, "Payment");
  });
};

const csvDownload = (csv, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob(["\uFEFF" + csv], { type: 'text/csv' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}.csv`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}.csv`);
    fakeLink.click();
  }
};

export default AccountingList
import React from 'react';
import { List, Datagrid, NumberField, EditButton, TextField } from 'react-admin';

const EvTimeList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Start time" source="start_time" />
            <TextField label="End time" source="end_time" />
            <NumberField lable="Price per km" source="price_per_km" />
            <NumberField lable="Startup price" source="startup_price" />
            <EditButton />
        </Datagrid>
    </List>
);

export default EvTimeList
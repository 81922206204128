import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput, EditButton } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Main service" source="main_service.name_th_contains" />
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ConditionOfServiceList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Main service" source="main_service.name_th" />
            <TextField label="Scope" source="scope_th" />
            <TextField label="Price detail" source="price_detail_th" />
            <TextField label="Payment term" source="payment_term_th" />
            <TextField label="Term of service" source="term_of_service_th" />
            <TextField label="Term of cancel" source="term_of_cancel_th" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default ConditionOfServiceList
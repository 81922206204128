import React from 'react';
import { List, Datagrid, TextField, ReferenceField, FunctionField, ArrayField, ChipField, EditButton, ReferenceArrayField, Filter, SingleFieldList, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Provider" source="prov_id._id" reference="providerlights">
            <SelectInput optionText="company_name" />
        </ReferenceInput>
    </Filter>
)

const MgmtProviderMonthlyBillingList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ReferenceArrayField label="Provider" source="prov_ids" reference="providerlights">
                <SingleFieldList>
                    <ChipField source="company_name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ArrayField source="emails">
                <SingleFieldList >
                    <ChipField source="email" />
                </SingleFieldList>
            </ArrayField>
            <EditButton />
        </Datagrid>
    </List>
);

export default MgmtProviderMonthlyBillingList
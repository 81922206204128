import React from 'react';
import { List, Datagrid, TextField, Filter, BooleanInput, EditButton, BooleanField, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="acupuncture_massage_group" reference="acupuncturemassagegroups">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <BooleanInput label="Visible" source="visible" />
    </Filter>
)

const ServiceMaidList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Acupuncture massage groups" source="acupuncture_massage_group.name_th" />
            {/* <TextField label="Room size" source="room_size.name_th" /> */}
            <TextField label="Hours" source="hours" />
            <TextField label="price" source="price" />
            <BooleanField label="Tool include" source="tool_include" />
            <BooleanField label="Visible" source="visible" />
            <EditButton />
        </Datagrid>
    </List>
);

export default ServiceMaidList
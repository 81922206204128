import React from 'react';
import { List, Datagrid, TextField, SelectInput, AutocompleteInput, Filter, ReferenceInput, TextInput } from 'react-admin';
import { findAddress, get_province } from '../../services/address/finder';

const provinces = get_province().map(p => ({ value: p.province_code, label: p.province_th }))

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Company name" source="_id" reference="providerlights" filterToQuery={searchText => ({ company_name: searchText })}>
            <AutocompleteInput optionText="company_name" />
        </ReferenceInput>
        {/* <TextInput source="tel" /> */}
    </Filter>
)

const ProviderList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="company_name" />
            <TextField source="tel" />
        </Datagrid>
    </List>
);

export default ProviderList
import React from 'react';
import * as R from 'ramda'
import { SimpleForm, TextInput, FormDataConsumer, ReferenceInput, SelectInput, BooleanInput } from 'react-admin';
import { required } from '../../services/validate'

const Form = ({ ...props }) => (
    <SimpleForm {...props}>
        <FormDataConsumer>
            {({ formData, ...rest }) => {
                return (
                    <ReferenceInput
                        label="Main Service"
                        source={formData.main_service && formData.main_service._id ? "main_service._id" : "main_service._id"}
                        reference="mainservices"
                        fullWidth
                        validate={[required()]}>
                        <SelectInput optionText="name_th" />
                    </ReferenceInput>
                )
            }}
        </FormDataConsumer>
        <TextInput multiline source="scope_th" label="scope TH" fullWidth validate={[required()]} />
        <TextInput multiline source="scope_en" label="scope EN" fullWidth validate={[required()]} />
        <TextInput multiline source="scope_ja" label="scope JA" fullWidth validate={[required()]} />
        <TextInput multiline source="scope_zh" label="scope ZH" fullWidth validate={[required()]} />
        <TextInput multiline source="price_detail_th" label="Price detail TH" fullWidth validate={[required()]} />
        <TextInput multiline source="price_detail_en" label="Price detail EN" fullWidth validate={[required()]} />
        <TextInput multiline source="price_detail_ja" label="Price detail JA" fullWidth validate={[required()]} />
        <TextInput multiline source="price_detail_zh" label="Price detail ZH" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_th" label="Payment Term TH" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_en" label="Payment Term EN" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_ja" label="Payment Term JA" fullWidth validate={[required()]} />
        <TextInput multiline source="payment_term_zh" label="Payment Term ZH" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_service_th" label="Term of service TH" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_service_en" label="Term of service EN" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_service_ja" label="Term of service JA" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_service_zh" label="Term of service ZH" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_cancel_th" label="Term of cancel TH" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_cancel_en" label="Term of cancel EN" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_cancel_ja" label="Term of cancel JA" fullWidth validate={[required()]} />
        <TextInput multiline source="term_of_cancel_zh" label="Term of cancel ZH" fullWidth validate={[required()]} />
        <BooleanInput label="Visible" source="visible" />
    </SimpleForm>
);

export default Form;
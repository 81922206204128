import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, SelectField } from 'react-admin';
import orderstatustype from '../orderservices/orderstatustype'

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Ref." source="ref_contains" />
    </Filter>
)

const OrderTopupList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Ref." source="ref" />
            <TextField label="cost" source="cost" />
            <TextField label="Total" source="total" />
            <SelectField label="Status" source="status" choices={orderstatustype} />
            <EditButton />
        </Datagrid>
    </List>
);

export default OrderTopupList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, FunctionField, EditButton } from 'react-admin';
import { findAddress } from '../../services/address/finder';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="License plate" source="license_plate_contains" />
    </Filter>
)

const CarList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="License plate" source="license_plate" />
            <FunctionField label="Province" render={record => {
                const address = findAddress({ province_code: record.province });
                return `${address[0] ? address[0].province_th : record.province}`
            }}
            />
            <TextField label="Color" source="color" />
            <TextField label="Seat" source="seat" />
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default CarList
const CHANGE_COUNT_ORDER_WAIT = 'CHANGE_COUNT_ORDER_WAIT';

export const changeCountOrderWait = notification => ({
    type: CHANGE_COUNT_ORDER_WAIT,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_COUNT_ORDER_WAIT) {
        return payload;
    }
    return previousState;
};

import React from 'react';
import { List, Datagrid, TextField, EditButton, ReferenceField, Filter, ReferenceInput, SelectInput } from 'react-admin';

const ListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Third party" source="service_group._id" reference="servicegroups">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
        <ReferenceInput label="Main service" source="main_service._id" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const mgmtComimgSoonList = props => (
    <List {...props} filters={<ListFilter />}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Service group" source="service_group._id" reference="servicegroups">
                <TextField source="name_th" />
            </ReferenceField>
            <ReferenceField label="Main service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <EditButton />
            />
        </Datagrid>
    </List>
);

export default mgmtComimgSoonList
import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput, EditButton } from 'react-admin';
import { union } from 'ramda'
import { findAddress } from '../../services/address/finder';

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name_th_contains" />
        <ReferenceInput source="main_service" reference="mainservices">
            <SelectInput optionText="name_th" />
        </ReferenceInput>
    </Filter>
)

const ToolList = props => (
    <List {...props} filters={<ListFilter />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField label="Name" source="name_th" />
            <ReferenceField label="Main Service" source="main_service._id" reference="mainservices">
                <TextField source="name_th" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);


export default ToolList
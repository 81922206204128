const CHANGE_AIRPORT_NOTIFICATION = 'CHANGE_AIRPORT_NOTIFICATION';

export const changeAirportNotification = notification => ({
    type: CHANGE_AIRPORT_NOTIFICATION,
    payload: notification,
});

export default (previousState = 0, { type, payload }) => {
    if (type === CHANGE_AIRPORT_NOTIFICATION) {
        return payload;
    }
    return previousState;
};

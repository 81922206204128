const TYPE = [
    { id: 'admin', name: 'Admin' },
    { id: 'client', name: 'Client' },
    { id: 'provider', name: 'Provider' },
    { id: 'provider_admin', name: 'Provider Admin' },
    { id: 'thirdparty', name: 'Third Party' },
    { id: 'internalapp', name: 'Internal App' },

]
const CREATE_TYPE = TYPE.filter(t => t.id !== 'client')
const TYPE_OBJ = {
    ADMIN: 'admin',
    CLIENT: 'client',
    PROVIDER: 'provider',
    PROVIDER_ADMIN: 'provider_admin',
    THIRD_PARTY: 'thirdparty',
    INTERNAL_APP: 'internalapp'
}
export { TYPE_OBJ, CREATE_TYPE }
export default TYPE;